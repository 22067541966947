import shruthiBlow from '../shruthiWAV/shrutiBlow.wav'
import shruthiC from '../shruthiWAV/shrutiClow.wav'
import shruthiCsharp from '../shruthiWAV/shrutiCsharplow.wav'
import shruthiD from '../shruthiWAV/shrutiDlow.wav'
import shruthiDsharp from '../shruthiWAV/shrutiDsharplow.wav'
import shruthiE from '../shruthiWAV/shrutiE.wav'
import shruthiF from '../shruthiWAV/shrutiF.wav'
import shruthiFsharp from '../shruthiWAV/shrutiFsharp.wav'
import shruthiG from '../shruthiWAV/shrutiG.wav'
import shruthiGsharp from '../shruthiWAV/shrutiGsharp.wav'
import shruthiA from '../shruthiWAV/shrutiA.wav'
import shruthiAsharp from '../shruthiWAV/shrutiAsharp.wav'
import shruthiB from '../shruthiWAV/shrutiB.wav'

export const shruthiKeys = [
  {
    number: '0',
    letter: 'b',
    file: shruthiBlow
  },
  {
    number: '1',
    letter: 'c',
    file: shruthiC
  },
  {
    number: '1.5',
    letter: 'c#',
    file: shruthiCsharp
  },
  {
    number: '2',
    letter: 'd',
    file: shruthiD
  },
  {
    number: '2.5',
    letter: 'd#',
    file: shruthiDsharp
  },
  {
    number: '3',
    letter: 'e',
    file: shruthiE
  },
  {
    number: '4',
    letter: 'f',
    file: shruthiF
  },
  {
    number: '4.5',
    letter: 'f#',
    file: shruthiFsharp
  },
  {
    number: '5',
    letter: 'g',
    file: shruthiG
  },
  {
    number: '5.5',
    letter: 'g#',
    file: shruthiGsharp
  },
  {
    number: '6',
    letter: 'a',
    file: shruthiA
  },
  {
    number: '6.5',
    letter: 'a#',
    file: shruthiAsharp
  },
  {
    number: '7',
    letter: 'b',
    file: shruthiB
  }
]
