import React from 'react'
import {
  Grid,
  Typography,
  Card,
  makeStyles,
  CardContent,
  CardActionArea
} from '@material-ui/core'
import { ragamData } from './ragamData'
import { Link } from 'react-router-dom'

import RagamSearch from './RagamSearch'
import { motion } from 'framer-motion'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: 340,
    height: 340
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },

  controls: {
    // display: 'flex',
    position: 'right',
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  description: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    paddingTop: theme.spacing(1)
  },
  playIcon: {
    height: 20,
    width: 20
  }
}))

function Ragam () {
  const classes = useStyles()
  const pageVariants2 = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  }

  return (
    <>
      <div class='Ragam'>
        <div class='container'>
          <motion.div
            initial='initial'
            animate='in'
            exit='out'
            variants={pageVariants2}
            transition={{ duration: 0.6 }}
          >
            <div class='col align-items-center'>
              <center>
                <h2 class='font-weight-light'>ragam dictionary</h2>
                <br></br>
              </center>
            </div>
            <div class='col align-items-center'>
              <div class='col align-items-center'>
                <center>
                  <RagamSearch />
                </center>
              </div>
            </div>
            <center>
              {/* <br></br> */}
              <Typography variant='body1' color='textSecondary'>
                <Link to={'./melakartha-chart'}>Melakartha Ragam Chart</Link>
              </Typography>
              <br></br>
            </center>
            {/* <div class='row align-items-center my-5'> */}
            <Grid container justify='center' spacing='2'>
              {ragamData.map(value => (
                <Grid key={value} item>
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={`/ragam/${value.number}`}
                  >
                    <CardActionArea>
                      {/* <motion.li variants={item} /> */}

                      <Card className={classes.root}>
                        <div className={classes.details}>
                          <div className={classes.controls}>
                            <div>
                              <Typography component='h6' variant='h6'>
                                #{value.number}: {value.name}
                              </Typography>
                              <Typography
                                variant='subtitle2'
                                color='textSecondary'
                              >
                                S R{value.ri} G{value.ga} M{value.ma} P D
                                {value.da} N{value.ni} Ṡ{/* <hr></hr> */}
                              </Typography>
                              {/* <Divider
                                className={classes.divider}
                                orientation='horizontal'
                              /> */}
                              <Typography
                                variant='overline'
                                color='textSecondary'
                              >
                                {value.chakra} Chakra
                              </Typography>
                            </div>
                            {/* <IconButton aria-label="play/pause">
            <PlayArrowIcon className={classes.playIcon} />
          </IconButton> */}
                          </div>
                          <CardContent className={classes.content}>
                            <div className={classes.description}>
                              <Typography variant='body2' color='textSecondary'>
                                {value.description.split('\r\n')[0]}
                              </Typography>
                              <Typography
                                variant='overline'
                                color='textSecondary'
                              >
                                <Link to={`/ragam/${value.number}`}>
                                  {' '}
                                  More about {value.name}
                                </Link>
                              </Typography>
                            </div>
                          </CardContent>
                        </div>
                      </Card>
                    </CardActionArea>
                  </Link>
                </Grid>
              ))}
            </Grid>
            {/* </div> */}
          </motion.div>
        </div>
      </div>
    </>
  )
}

export default Ragam
