import React from 'react'
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  makeStyles
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { lessonsData } from './lessonsData'
import { motion } from 'framer-motion'

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    padding: theme.spacing(1),
    width: 330,
    height: 155
    // justifyContent: 'center'
    // align: 'center',
  },
  details: {
    display: 'flex'
    // flexDirection: 'column'
    // align: 'center'
  },
  media: {
    height: 250
  }
}))

function Lessons () {
  const classes = useStyles()
  const pageVariants2 = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  }

  return (
    <div className='home'>
      <div class='container'>
        <motion.div
          initial='initial'
          animate='in'
          exit='out'
          variants={pageVariants2}
          transition={{ duration: 0.6 }}
        >
          <div class='col align-items-center'>
            <center>
              <h2 class='font-weight-light'>learn</h2>
            </center>
          </div>
          <div class='row align-items-center my-5'>
            <div className='lessonsCards'>
              <Grid container justify='center' alignItems='center' spacing='2'>
                {lessonsData.map(value => (
                  <Grid key={value} item>
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/${value.link}`}
                    >
                      <CardActionArea>
                        <Card className={classes.root}>
                          <CardContent>
                            <div className={classes.details}>
                              <div>
                                <Typography component='h6' variant='h6'>
                                  {value.text}
                                </Typography>
                                <br></br>
                                <Typography
                                  variant='body1'
                                  color='textSecondary'
                                >
                                  {value.subtitle}
                                </Typography>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </CardActionArea>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Lessons
