import React from 'react'
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  makeStyles,
  Link
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { alankaramsLessonData } from './alankaramsLessonData'
import { motion } from 'framer-motion'
import LessonNavigation from './LessonNavigation'
import BreadCrumbNavigation from './BreadCrumbNavigation'

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    padding: theme.spacing(1),
    width: 330,
    height: 100
    // justifyContent: 'center'
    // align: 'center',
    // flexDirection: 'column'
  },
  details: {
    // display: 'flex'
    // align: 'center'
  },
  media: {
    height: 250
  }
}))

function Alankaram () {
  const classes = useStyles()
  const pageVariants2 = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  }

  return (
    <div className='home'>
      <div class='container'>
        <motion.div
          initial='initial'
          animate='in'
          exit='out'
          variants={pageVariants2}
          transition={{ duration: 0.6 }}
        >
          <div>
            <center>
              <BreadCrumbNavigation
                crumb1='Learn'
                crumb2='Alankaram'
                crumb3=' '
                extraURLstring='.'
              />
            </center>
          </div>

          <div class='col align-items-center'>
            <center>
              <h2 class='font-weight-light'>alankarams</h2>
              <h6 class='font-weight-light'>
                Exercises based on the seven talams to practice keeping rhythm.
              </h6>
            </center>
          </div>
          <LessonNavigation
            stepButtonLink='./alankaram'
            steps={['', '', '', '', '', '', '', '', '']}
            titles={[
              'Alankaram #1: Chatusra Jati Dhruva Talam',
              'Alankaram #2: Chatusra Jati Matya Talam',
              'Alankaram #3: Chatusra Jati Rupaka Talam',
              'Alankaram #4: Misra Jati Jhampa Talam',
              'Alankaram #5: Tisra Jati Triputa Talam',
              'Alankaram #6: Khanda Jati Ata Talam',
              'Alankaram #7: Chatusra Jati Eka Talam',
              'Alankaram #8: Sankeerna Jati Eka Talam v.1',
              'Alankaram #9: Sankeerna Jati Eka Talam v.2'
            ]}
            activeStep={100}
          />

          {/* <div class='row align-items-center my-5'> */}
          <Grid container justify='center' alignItems='center' spacing='2'>
            {alankaramsLessonData.map(value => (
              <Grid key={value} item>
                <Link
                  component={RouterLink}
                  style={{ textDecoration: 'none' }}
                  to={`./alankaram/${value.number}`}
                >
                  <CardActionArea>
                    <Card className={classes.root}>
                      <CardContent>
                        <div className={classes.details}>
                          <div>
                            <Typography component='h6' variant='h6'>
                              Alankaram #{value.number}
                            </Typography>
                            <Typography variant='body1' color='textSecondary'>
                              {value.talam}
                            </Typography>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </CardActionArea>
                </Link>
              </Grid>
            ))}
          </Grid>
          {/* </div> */}
        </motion.div>
      </div>
    </div>
  )
}

export default Alankaram
