import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import logo from '../swarigamaLogo.svg'

function Header (props) {
  return (
    <div className='header'>
      <nav class='navbar navbar-expand-lg'>
        <div class='container'>
          <Link class='navbar-brand' to='/'>
            <img class='logo' src={logo} alt='My logo' />
            {/* swarigama */}
          </Link>

          <button
            class='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarResponsive'
            aria-controls='navbarResponsive'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span class='navbar-toggler-icon'></span>
          </button>
          <div class='collapse navbar-collapse' id='navbarResponsive'>
            <ul class='navbar-nav ml-auto'>
              {/* <li
                class={`nav-item  ${
                  props.location.pathname === '/melakartha-chart'
                    ? 'active'
                    : ''
                }`}
              >
                <Link class='nav-link' to='/melakartha-chart'>
                  melakartha chart
                </Link>
              </li> */}
              <li
                class={`nav-item  ${
                  props.location.pathname === '/shruthi' ? 'active' : ''
                }`}
              >
                <Link class='nav-link' to='/shruthi'>
                  shruthi + talam
                </Link>
              </li>
              <li
                class={`nav-item  ${
                  props.location.pathname === '/ragam' ? 'active' : ''
                }`}
              >
                <Link class='nav-link' to='/ragam'>
                  ragam
                </Link>
              </li>
              <li
                class={`nav-item  ${
                  props.location.pathname === '/learn' ? 'active' : ''
                }`}
              >
                <Link class='nav-link' to='/learn'>
                  learn
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default withRouter(Header)
