import React from 'react'
import { beginnerLessonData } from './beginnerLessonData'
import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  IconButton,
  makeStyles
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import ArrowLeft from '@material-ui/icons/ChevronLeft'
import ArrowRight from '@material-ui/icons/ChevronRight'

import LessonNavigation from './LessonNavigation'
import BreadCrumbNavigation from './BreadCrumbNavigation'
import { motion } from 'framer-motion'

const useStyles = makeStyles(theme => ({
  box: {
    width: '100%'
  },
  root: {
    position: 'center'
  },
  content: {
    flex: '1 0 auto'
  },

  controls: {
    position: 'right',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  icon: {
    height: 50,
    width: 50
    // fill: '#f4a261',
    // '&:hover': {
    //   fill: '#e76f51'
    // }
  }
}))

function VarasaiPage ({ match, location }) {
  const classes = useStyles()
  const {
    params: { id, lessonId }
  } = match
  const pageVariants = {
    initial: {
      opacity: 0,
      x: '-100vw',
      scale: 0.8
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1
    },
    out: {
      opacity: 0,
      x: 0,
      scale: 1.2
    }
  }
  const pageVariants2 = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  }
  function getLink (stringId) {
    var str1 = './'
    return str1.concat(stringId)
  }

  function getSteps (notes) {
    const len = notes.length
    const arr = []
    for (let i = 0; i < len; i++) {
      arr.push(' ')
    }

    return arr
  }

  var crumb3 = beginnerLessonData[id - 1].varasai
  crumb3 = crumb3.concat(' Varasai')
  crumb3 = crumb3.concat(' #')
  crumb3 = crumb3.concat(lessonId)

  return (
    <>
      <div class='container'>
        <motion.div
          initial='initial'
          animate='in'
          exit='out'
          variants={pageVariants2}
          transition={{ duration: 0.6 }}
        >
          <div>
            <center>
              <BreadCrumbNavigation
                crumb1='Learn'
                crumb2='Beginner Varasai'
                crumb3={crumb3}
                extraURLstring='..'
              />
            </center>
          </div>
          {/* <div class='row align-items-center my-5'> */}
          <Grid
            container
            spacing={4}
            direction='row'
            alignItems='center'
            justify='center'
          >
            <Grid item>
              {parseInt(lessonId) - 1 === 0 ? (
                <IconButton disabled='true' color='link'>
                  <ArrowLeft variant='contained' className={classes.icon} />
                </IconButton>
              ) : (
                <IconButton
                  color='primary'
                  component={Link}
                  to={getLink(parseInt(lessonId) - 1)}
                >
                  <ArrowLeft variant='contained' className={classes.icon} />
                </IconButton>
              )}
            </Grid>
            <Grid item>
              <motion.div
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <Card className={classes.root}>
                  <CardContent className={classes.content}>
                    <div className={classes.controls}>
                      <div>
                        <Typography component='h5' variant='h5'>
                          {beginnerLessonData[id - 1].varasai} Varasai #
                          {lessonId}
                        </Typography>
                      </div>
                      <div>
                        <Typography variant='body1' color='textSecondary'>
                          Ragam: Mayamalavagowla
                        </Typography>
                      </div>
                      <div>
                        <Typography variant='body1' color='textSecondary'>
                          Talam: Adi
                        </Typography>
                      </div>
                      <div>
                        <br></br>
                        <Typography variant='subtitle1' color='textSecondary'>
                          {beginnerLessonData[id - 1].notes[lessonId - 1]
                            .split('\r\n')
                            .map(index => (
                              <p>{index}</p>
                            ))}
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
            <Grid item>
              {parseInt(lessonId) + 1 ===
              beginnerLessonData[id - 1].notes.length + 1 ? (
                <IconButton disabled='true' color='link'>
                  <ArrowRight variant='contained' className={classes.icon} />
                </IconButton>
              ) : (
                <IconButton
                  color='primary'
                  component={Link}
                  to={getLink(parseInt(lessonId) + 1)}
                >
                  <ArrowRight variant='contained' className={classes.icon} />
                </IconButton>
              )}
            </Grid>
            <LessonNavigation
              stepButtonLink='.'
              steps={getSteps(beginnerLessonData[id - 1].notes)}
              titles={getSteps(beginnerLessonData[id - 1].notes)}
              activeStep={parseInt(window.location.href.split('/').pop()) - 1}
            />
          </Grid>
          {/* </div> */}
        </motion.div>
      </div>
    </>
  )
}

export default VarasaiPage
