import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions
} from '@material-ui/core'
import { splashData } from './splashData'
import { motion } from 'framer-motion'

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    padding: theme.spacing(1),
    width: 370,
    height: 370
    // justifyContent: 'center'
    // align: 'center',
  },
  details: {
    display: 'flex'
    // flexDirection: 'column'
    // align: 'center'
  },
  media: {
    height: 250
  }
}))

function Home () {
  const classes = useStyles()
  const pageVariants2 = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  }
  return (
    <div className='home'>
      <div class='container'>
        <motion.div
          initial='initial'
          animate='in'
          exit='out'
          variants={pageVariants2}
          transition={{ duration: 0.6 }}
        >
          <div class='row align-items-center my-5'>
            <div class='col-lg-7'>
              <img
                class='img-fluid rounded mb-4 mb-lg-0'
                src='http://placehold.it/900x400'
                alt=''
              />
            </div>
            <div class='col-lg-5'>
              <h1 class='font-weight-light'>
                welcome to your friendly carnatic music resource.
              </h1>
              <p>
                from sa ri ga ma <br></br>to pa da ni sa
              </p>
            </div>
            {/* <div className='splashCards'>
              <Grid container justify='center' alignItems='center' spacing='2'>
                {splashData.map(value => (
                  <Grid key={value} item>
                    <Card className={classes.root}>
                      <CardMedia
                        className={classes.media}
                        image={value.image}
                      />
                      <CardContent>
                        <div className={classes.details}>
                          <div>
                            <Typography variant='body1' align='center'>
                              {value.text}
                            </Typography>
                          </div>
                        </div>
                      </CardContent>
                      <CardActions>
                        <center>
                          <Button
                            variant='contained'
                            size='small'
                            color='link'
                            href={`/${value.link}`}
                          >
                            {value.buttontext}
                          </Button>
                        </center>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div> */}
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Home
