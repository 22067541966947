import React from 'react'
import {
  Grid,
  Typography,
  Card,
  makeStyles,
  CardContent,
  CardActionArea
} from '@material-ui/core'
import { ragamData } from './ragamData'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(3)
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

function RagamCard (props) {
  const classes = useStyles()
  if (isNaN(props.id) === false) {
    var value = ragamData[parseInt(props.id) - 1]
  }

  return (
    <div>
      <Card className={classes.root}>
        {isNaN(props.id) === false ? (
          // <div>
          //   <Typography component='h6' variant='h6'>
          //     #{props.id}:
          //   </Typography>
          //   <Typography variant='overline' color='textSecondary'>
          //     {ragamData[parseInt(props.id) - 1].name}
          //   </Typography>
          // </div>
          <div>
            {/* <Link
              style={{ textDecoration: 'none' }}
              to={`/ragam/${value.number}`}
            > */}
            <div className={classes.details}>
              <div>
                <Typography component='h6' variant='h6'>
                  #{value.number}: {value.name}
                </Typography>
                <Typography variant='subtitle2' color='textSecondary'>
                  S R{value.ri} G{value.ga} M{value.ma} P D{value.da} N
                  {value.ni} Ṡ{/* <hr></hr> */}
                </Typography>
                {/* <Typography variant='overline' color='textSecondary'>
                  {value.chakra} Chakra
                </Typography> */}
              </div>
            </div>
          </div>
        ) : (
          <Typography component='h6' variant='h6'>
            {props.id}
          </Typography>
        )}
      </Card>
    </div>
  )
}
export default RagamCard
