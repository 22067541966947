import React from 'react'
import { ragamData } from './ragamData'
import { Typography, makeStyles, Card, CardContent } from '@material-ui/core'
import PianoPlayer from './PianoPlayer'
import { motion } from 'framer-motion'

const audioContext = new (window.AudioContext || window.webkitAudioContext)()
const soundfontHostname = 'https://d1pzp51pvbm36p.cloudfront.net'

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex'
    // width: 500,
    // width: 500,
    // position: 'center'
  },
  content: {
    // display: 'flex',
    // flex: '1 0 auto'
    display: 'flex',
    flexDirection: 'column'
  },

  controls: {
    position: 'right',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  scale: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  description: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  janyas: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  compositions: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  keyboard: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 20,
    width: 20
  }
}))

function RagamPage ({ match, location }) {
  const classes = useStyles()
  const {
    params: { userId }
  } = match
  // const raga1 = [[]]

  React.useEffect(() => getPianoNotes(), [])

  function getPianoNotes () {
    var x = []
    x.push([48])
    switch (ragamData[userId - 1].ri) {
      case '₁':
        x.push([49])
        break
      case '₂':
        x.push([50])
        break
      default:
        // R3
        x.push([51])
    }
    switch (ragamData[userId - 1].ga) {
      case '₁':
        x.push([50])
        break
      case '₂':
        x.push([51])
        break
      default:
        // G3
        x.push([52])
    }
    switch (ragamData[userId - 1].ma) {
      case '₁':
        x.push([53])
        break
      default:
        // M2
        x.push([54])
    }
    x.push([55])
    switch (ragamData[userId - 1].da) {
      case '₁':
        x.push([56])
        break
      case '₂':
        x.push([57])
        break
      default:
        // D3
        x.push([58])
    }
    switch (ragamData[userId - 1].ni) {
      case '₁':
        x.push([57])
        break
      case '₂':
        x.push([58])
        break
      default:
        // N3
        x.push([59])
    }
    x.push([60], [60], [], [60])

    switch (ragamData[userId - 1].ni) {
      case '₁':
        x.push([57])
        break
      case '₂':
        x.push([58])
        break
      default:
        // N3
        x.push([59])
    }
    switch (ragamData[userId - 1].da) {
      case '₁':
        x.push([56])
        break
      case '₂':
        x.push([57])
        break
      default:
        // D3
        x.push([58])
    }
    x.push([55])
    switch (ragamData[userId - 1].ma) {
      case '₁':
        x.push([53])
        break
      default:
        // M2
        x.push([54])
    }

    switch (ragamData[userId - 1].ga) {
      case '₁':
        x.push([50])
        break
      case '₂':
        x.push([51])
        break
      default:
        // G3
        x.push([52])
    }
    switch (ragamData[userId - 1].ri) {
      case '₁':
        x.push([49])
        break
      case '₂':
        x.push([50])
        break
      default:
        // R3
        x.push([51])
    }
    x.push([48], [48], [48], [], [], [], [])
    setPianoNotes(x)
  }
  const [pianoNotes, setPianoNotes] = React.useState([])

  const pageVariants2 = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  }

  return (
    <>
      <div class='container'>
        <motion.div
          initial='initial'
          animate='in'
          exit='out'
          variants={pageVariants2}
          transition={{ duration: 0.6 }}
        >
          <div class='row align-items-center my-5'>
            {/* <CardActionArea> */}
            <Card className={classes.root}>
              <CardContent className={classes.content}>
                <div className={classes.controls}>
                  <div>
                    <Typography component='h5' variant='h5'>
                      #{ragamData[userId - 1].number}:{' '}
                      {ragamData[userId - 1].name}
                    </Typography>
                    {/* <IconButton aria-label='play/pause'>
                    <PlayArrowIcon className={classes.playIcon} />
                  </IconButton> */}
                  </div>

                  <div className={classes.scale}>
                    <Typography variant='h6' color='textSecondary'>
                      {ragamData[userId - 1].chakra} Chakra
                    </Typography>
                    <br></br>
                    <Typography variant='caption' color='textSecondary'>
                      SCALE
                    </Typography>
                    <Typography variant='body1' color='textSecondary'>
                      Arohanam:
                    </Typography>
                    <Typography variant='subtitle1' color='textSecondary'>
                      S R{ragamData[userId - 1].ri} G{ragamData[userId - 1].ga}{' '}
                      M{ragamData[userId - 1].ma} P D{ragamData[userId - 1].da}{' '}
                      N{ragamData[userId - 1].ni} Ṡ
                    </Typography>
                    <Typography variant='body1' color='textSecondary'>
                      Avarohanam:
                    </Typography>
                    <Typography variant='subtitle1' color='textSecondary'>
                      Ṡ N{ragamData[userId - 1].ni} D{ragamData[userId - 1].da}{' '}
                      P M{ragamData[userId - 1].ma} G{ragamData[userId - 1].ga}{' '}
                      R{ragamData[userId - 1].ri} S
                    </Typography>
                  </div>

                  <div className={classes.description}>
                    <Typography variant='caption' color='textSecondary'>
                      DESCRIPTION
                    </Typography>

                    <Typography variant='body1' color='textSecondary'>
                      {ragamData[userId - 1].description
                        .split('\r\n')
                        .map(index => (
                          <p>{index}</p>
                        ))}
                    </Typography>
                  </div>
                  <div className={classes.compositions}>
                    <Typography variant='caption' color='textSecondary'>
                      SELECTED COMPOSITIONS
                    </Typography>

                    <br></br>
                    <Typography variant='overline' color='textSecondary'>
                      <a
                        href={ragamData[userId - 1].playlist}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {' '}
                        PLAYLIST OF COMPOSITIONS IN {ragamData[userId - 1].name}
                      </a>
                    </Typography>
                  </div>
                  <div className={classes.keyboard}>
                    <Typography variant='caption' color='textSecondary'>
                      KEYBOARD
                    </Typography>

                    {/* <Typography variant='body1' color='textSecondary'> */}
                    {/* <Keyboard /> */}
                    <PianoPlayer
                      audioContext={audioContext}
                      soundfontHostname={soundfontHostname}
                      song={pianoNotes}
                    />
                    {/* </Typography> */}
                  </div>
                  <div className={classes.janyas}>
                    <Typography variant='caption' color='textSecondary'>
                      JANYA (CHILD) RAGAMS
                    </Typography>

                    <Typography variant='body1' color='textSecondary'>
                      {ragamData[userId - 1].janyas.split('\r\n').map(index => (
                        <li>{index}</li>
                      ))}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
            {/* </CardActionArea> */}
          </div>
        </motion.div>
      </div>
    </>
  )
}

export default RagamPage
