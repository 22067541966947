import React, { useState, useEffect } from 'react'
import {
  Grid,
  Paper,
  Typography,
  Tooltip,
  Slider,
  makeStyles,
  IconButton
} from '@material-ui/core'

import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PauseIcon from '@material-ui/icons/Pause'
import PanTool from '@material-ui/icons/PanTool'
import PanToolOutlined from '@material-ui/icons/PanToolOutlined'

import NumberIcon from '@material-ui/icons/LooksOne'
import LetterIcon from '@material-ui/icons/FontDownload'
import { shruthiKeys } from './shruthiKeys'

import { motion } from 'framer-motion'
import TalamCard from './TalamCard'

const useStyles = makeStyles(theme => ({
  root: {
    width: 400,
    height: 460,
    position: 'center',
    textAlign: 'center'
  },
  content: {
    position: 'center',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  content2: {
    position: 'center',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  icon: {
    height: 30,
    width: 30,
    fill: '#f4a261',
    '&:hover': {
      fill: '#e76f51'
    }
  },
  root2: {
    position: 'center',
    textAlign: 'center',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  input: {
    width: 42
  }
}))

function Shruthi () {
  const [playClicked, setPlayClicked] = useState(true)
  const [talamClicked, setTalamClicked] = useState(false)
  const [letterClicked, setLetterClicked] = useState(true)

  const [currentShruthiKey, setShruthi] = useState(10)

  function handlePlayIconClick () {
    if (playClicked === false) {
      setPlayClicked(true)
      document.getElementById('audio').pause()
    } else {
      setPlayClicked(false)
      document.getElementById('audio').play()
    }
  }

  function handleTalamIconClick () {
    if (talamClicked === false) {
      setTalamClicked(true)
    } else {
      setTalamClicked(false)
    }
  }

  function handleLetterIconClick () {
    if (letterClicked === false) {
      setLetterClicked(true)
    } else {
      setLetterClicked(false)
    }
  }

  function handleShruthiIncrease () {
    if (currentShruthiKey === 12) {
      return
    }
    setShruthi(currentShruthiKey + 1)
  }
  function handleShruthiDecrease () {
    if (currentShruthiKey === 0) {
      return
    }
    setShruthi(currentShruthiKey - 1)
  }

  const classes = useStyles()

  const pageVariants2 = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  }
  return (
    <>
      <div>
        <audio
          id='audio'
          src={shruthiKeys[currentShruthiKey].file}
          loop
          autoPlay={!playClicked}
        />
      </div>
      <div class='container'>
        <motion.div
          initial='initial'
          animate='in'
          exit='out'
          variants={pageVariants2}
          transition={{ duration: 0.6 }}
        >
          <div class='col align-items-center'>
            <center>
              <h2 class='font-weight-light'>shruthi + talam </h2>
            </center>
          </div>
          <div class='row align-items-center my-5'>
            <div class='col align-items-center'>
              <Grid container justify='center' spacing='3'>
                <Grid item>
                  <Paper className={classes.root}>
                    <div className={classes.content}>
                      <Typography variant='overline' color='textSecondary'>
                        Key
                      </Typography>
                      <div>
                        <Tooltip title='INCREASE KEY' placement='right'>
                          <IconButton
                            onClick={handleShruthiIncrease}
                            aria-label='up'
                          >
                            <ExpandLessIcon className={classes.icon} />
                          </IconButton>
                        </Tooltip>

                        {letterClicked ? (
                          <Typography component='h2' variant='h2'>
                            {shruthiKeys[currentShruthiKey].letter}
                          </Typography>
                        ) : (
                          <Typography component='h2' variant='h2'>
                            {shruthiKeys[currentShruthiKey].number}
                          </Typography>
                        )}

                        <Tooltip title='DECREASE KEY' placement='right'>
                          <IconButton
                            onClick={handleShruthiDecrease}
                            aria-label='down'
                          >
                            <ExpandMoreIcon className={classes.icon} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className={classes.content}>
                      <Typography variant='overline' color='textSecondary'>
                        Shruthi Controls
                      </Typography>
                    </div>
                    {/* <Divider className={classes.divider} orientation='horizontal' /> */}
                    <div className={classes.content2}>
                      <Tooltip title={talamClicked ? 'TALAM OFF' : 'TALAM ON'}>
                        <IconButton
                          aria-label='talam on'
                          onClick={handleTalamIconClick}
                        >
                          {talamClicked ? (
                            <PanTool className={classes.icon} />
                          ) : (
                            <PanToolOutlined className={classes.icon} />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={playClicked ? 'PLAY' : 'PAUSE'}>
                        <IconButton
                          aria-label='play'
                          onClick={handlePlayIconClick}
                        >
                          {playClicked ? (
                            <PlayArrowIcon className={classes.icon} />
                          ) : (
                            <PauseIcon className={classes.icon} />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='LETTER/NUMBER MODE'>
                        <IconButton
                          aria-label='letter mode'
                          onClick={handleLetterIconClick}
                        >
                          {letterClicked ? (
                            <LetterIcon className={classes.icon} />
                          ) : (
                            <NumberIcon className={classes.icon} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Paper>
                </Grid>
                {!talamClicked ? (
                  <Grid item>
                    <TalamCard />
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  )
}

export default Shruthi
