import React from 'react'
import {
  makeStyles,
  Stepper,
  Step,
  StepButton,
  Typography,
  Grid
} from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2),
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: 'inline-block'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

export default function LessonNavigation (props) {
  const classes = useStyles()

  const steps = getSteps(props)

  const totalSteps = () => {
    return steps.length
  }
  function getSteps (props) {
    return props.steps
  }

  function getStepContent (step) {
    return props.titles[step]
  }

  function getLink (stringId) {
    var str1 = props.stepButtonLink
    str1 = str1.concat('/')
    return str1.concat(stringId)
  }

  return (
    <div className={classes.root}>
      <Stepper
        nonLinear
        alternativeLabel
        activeStep={props.activeStep}
        style={{ backgroundColor: 'transparent' }}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton
              component={Link}
              to={getLink(parseInt(index) + 1)}
              // onClick={event => setActiveStep(index)}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <center>
          <Grid width='100%' display='flex'>
            <Typography variant='body1' className={classes.instructions}>
              {getStepContent(props.activeStep)}
            </Typography>
          </Grid>
        </center>
      </div>
    </div>
  )
}
