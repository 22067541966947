import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  Grid,
  Paper,
  Typography,
  Tooltip,
  Slider,
  makeStyles,
  IconButton
} from '@material-ui/core'

import click1Sound from '../shruthiWAV/click1.wav'
import click2Sound from '../shruthiWAV/click2.wav'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import ArrowRight from '@material-ui/icons/ArrowRight'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PauseIcon from '@material-ui/icons/Pause'

import finger1 from '../talamHandFrames/finger1.png'
import finger2 from '../talamHandFrames/finger2.png'
import finger3 from '../talamHandFrames/finger3.png'
import finger4 from '../talamHandFrames/finger4.png'
import finger5 from '../talamHandFrames/finger5.png'
import flipHand from '../talamHandFrames/flipHand.png'
import fullHand from '../talamHandFrames/fullHand.png'
import emptyHand from '../talamHandFrames/emptyHand.png'

import { jatiTypes } from './jatiTypes'
import { talamFamilies } from './talamFamilies'

const useStyles = makeStyles(theme => ({
  root: {
    width: 400,
    height: 460,
    position: 'center',
    textAlign: 'center'
  },
  content: {
    position: 'center',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  content2: {
    position: 'center',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  icon: {
    height: 30,
    width: 30,
    fill: '#f4a261',
    '&:hover': {
      fill: '#e76f51'
    }
  },
  root2: {
    position: 'center',
    textAlign: 'center',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  input: {
    width: 42
  }
}))

export default function TalamCard () {
  const classes = useStyles()
  const [talaImages, setTalaImages] = React.useState([emptyHand])
  const [currentTalaIndex, setCurrentTalaIndex] = React.useState(0) // Talam Frame!
  const [currentTalamKey, setTalam] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [count, setCount] = useState(0)
  const [bpm, setBpm] = useState(100)
  const timer = useRef()
  const [beatsPerMeasure, setBeatsPerMeasure] = useState(0)

  const click1 = new Audio(click1Sound)
  const click2 = new Audio(click2Sound)

  const playClickCallback = useCallback(() => {
    if (count % beatsPerMeasure === 0) {
      click1.play()
    } else {
      click1.play()
    }
    changeTalaIndex()
    setCount(prevCount => (prevCount + 1) % beatsPerMeasure)
  }, [count, click1, click2])

  useEffect(() => {
    if (playing) {
      clearInterval(timer.current)
      timer.current = setInterval(playClickCallback, (60 / bpm) * 1000)
    } else {
      clearInterval(timer.current)
    }
  }, [bpm, playing, playClickCallback])

  const startStop = () => {
    if (playing) {
      // getAnimations()
      // setBeatsPerMeasure(talaImages.length)
      // console.log(beatsPerMeasure)
      setPlaying(false)
    } else {
      setCount(0)
      setPlaying(true)
    }
  }

  const handleSliderChange = (event, newValue) => {
    setBpm(newValue)
    if (playing) {
      setCount(0)
    }
  }

  function changeTalaIndex () {
    if (currentTalaIndex === talaImages.length - 1) {
      setCurrentTalaIndex(currentTalaIndex => 0)
    } else {
      setCurrentTalaIndex(currentTalaIndex => currentTalaIndex + 1)
    }
    // console.log(currentTalaIndex)
  }

  function handleTalamIncrease () {
    setCurrentTalaIndex(0)
    if (currentTalamKey === 7) {
      setTalam(0)
    } else {
      setTalam(currentTalamKey + 1)
      // getAnimations()
    }
  }
  function handleTalamDecrease () {
    setCurrentTalaIndex(0)
    if (currentTalamKey === 0) {
      setTalam(7)
    } else {
      setTalam(currentTalamKey - 1)
      // getAnimations()
    }
  }
  function getAnimations () {
    // get current Talam anga and jati

    var anga = talamFamilies[currentTalamKey].anga
    var jati = talamFamilies[currentTalamKey].defaultJati
    var jatiCount = jatiTypes[jati]
    setTalaImages(talaImages => [])
    var x = []
    for (var i = 0; i < anga.length; i++) {
      // convert anga to string
      if (anga.charAt(i) === 'I') {
        // I = (fullHand, finger1, finger2, finger3, finger4, finger 5, as determined by jati)
        if (jatiCount === 3) {
          x.push(fullHand)
          x.push(finger1)
          x.push(finger2)
        } else if (jatiCount === 4) {
          x.push(fullHand)
          x.push(finger1)
          x.push(finger2)
          x.push(finger3)
        } else if (jatiCount === 5) {
          x.push(fullHand)
          x.push(finger1)
          x.push(finger2)
          x.push(finger3)
          x.push(finger4)
        } else if (jatiCount === 7) {
          x.push(fullHand)
          x.push(finger1)
          x.push(finger2)
          x.push(finger3)
          x.push(finger4)
          x.push(finger5)
          x.push(finger4)
        } else if (jatiCount === 9) {
          x.push(fullHand)
          x.push(finger1)
          x.push(finger2)
          x.push(finger3)
          x.push(finger4)
          x.push(finger5)
          x.push(finger4)
          x.push(finger3)
          x.push(finger2)
        }
      } else if (anga.charAt(i) === 'O') {
        // O = (fullHand, flipHand)
        x.push(fullHand)
        x.push(flipHand)
      } else {
        // U = (fullHand)
        x.push(fullHand)
      }
    }

    setTalaImages(talaImages => x)
    console.log(talaImages)
  }

  return (
    <div>
      <Paper className={classes.root}>
        <div className={classes.content}>
          <div>
            <Typography variant='overline' color='textSecondary'>
              Talam
            </Typography>
          </div>
          <div class='row'>
            <div class='col-sm-3'></div>
            <div class='col-sm-6'>
              {/* <img
                class='img-fluid'
                src={talaImages[currentTalaIndex]}
                alt=''
              /> */}
            </div>
            <div class='col-sm-3'></div>
          </div>
          <div>
            <Typography variant='h6' color='textSecondary'>
              <Tooltip title='CHANGE TALAM' placement='left'>
                <IconButton aria-label='Change Talam'>
                  <ArrowLeft
                    onClick={handleTalamDecrease}
                    className={classes.icon}
                  />
                </IconButton>
              </Tooltip>
              {talamFamilies[currentTalamKey].name}

              <Tooltip title='CHANGE TALAM' placement='right'>
                <IconButton aria-label='Change Talam'>
                  <ArrowRight
                    onClick={handleTalamIncrease}
                    className={classes.icon}
                  />
                </IconButton>
              </Tooltip>
            </Typography>
            <div>
              <Typography variant='overline' color='textSecondary'>
                {talamFamilies[currentTalamKey].defaultJati} Jati
              </Typography>
            </div>
            <div>
              <Typography variant='h6' color='textSecondary'>
                {talamFamilies[currentTalamKey].anga}
              </Typography>
            </div>
            <div className={classes.root2}>
              <Typography variant='overline' color='textSecondary'>
                Tala Speed (bpm)
              </Typography>
              <Tooltip title={playing ? 'PAUSE' : 'PLAY'}>
                <IconButton aria-label='play' onClick={startStop}>
                  {playing ? (
                    <PauseIcon className={classes.icon} />
                  ) : (
                    <PlayArrowIcon className={classes.icon} />
                  )}
                </IconButton>
              </Tooltip>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs>
                  {/* <div>{bpm} BPM</div> */}
                  <Slider
                    // bpm={typeof bpm === 'number' ? bpm : 0}
                    min={60}
                    max={240}
                    defaultValue={100}
                    onChange={handleSliderChange}
                    aria-labelledby='discrete-slider'
                    valueLabelDisplay='auto'
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  )
}
