export const ragamData = [
  {
    number: '1',
    name: 'Kanakangi',
    ri: '₁',
    ga: '₁',
    ma: '₁',
    da: '₁',
    ni: '₁',
    chakra: 'Indu',
    description:
      'Kanakangi means the golden-bodied one and is the 1st Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Kanakambari in the Muthuswami Dikshitar school. It is the 1st ragam in the 1st chakra Indu. The mnemonic name is Indu-Pa. The mnemonic phrase is sa ra ga ma pa dha na.',
    janyas:
      'Kanakāmbari\r\nKanakatodi\r\nMādhavapriyā\r\nKarnātaka Shuddha Sāveri\r\nLatantapriya\r\nLavangi\r\nMegha\r\nRishabhavilāsa\r\nSarvashree\r\nSuddha Mukhāri\r\nTatillatika\r\nVāgeeshwari',
    compositions: 'composition1,composition2,composition3',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXscgl68kIJnoBSE6lulO3Lp'
  },
  {
    number: '2',
    name: 'Ratnangi',
    ri: '₁',
    ga: '₁',
    ma: '₁',
    da: '₁',
    ni: '₂',
    chakra: 'Indu',
    description:
      'Ratnangi means the one with a gem-like body and is the 2nd melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Phenadhyuti in Muthuswami Dikshitar school of Carnatic music. It is the 2nd ragam in the 1st chakra Indu. The mnemonic name is Indu-Sri. The mnemonic phrase is sa ra ga ma pa dha ni.',
    janyas:
      'Phenadhyuti\r\nGanamukhāri\r\nRatnavarāli\r\nRevati\r\nShreemani\r\nShreemati\r\nSvadhya',
    compositions: 'composition1,composition2,composition4',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXv8nSMN2r8uZz3WwLCjk--C'
  },
  {
    number: '3',
    name: 'Ganamurti',
    ri: '₁',
    ga: '₁',
    ma: '₁',
    da: '₁',
    ni: '₃',
    chakra: 'Indu',
    description:
      'Ganamurti means the idol of music and is the 3rd Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Ganasamavarali in Muthuswami Dikshitar school of Carnatic music. It is the 3rd ragam in the 1st chakra Indu. The mnemonic name is Indu-Go. The mnemonic phrase is sa ra ga ma pa dha nu.',
    janyas:
      'Gānasāmavarāli\r\nBhinnapanchamam\r\nNādharanjani\r\nPoorvavarāli\r\nSāmavarāli',
    compositions: 'composition1,composition2,composition5',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXu_9JPRmlozXxF4neXg5LNz'
  },
  {
    number: '4',
    name: 'Vanaspati',
    ri: '₁',
    ga: '₁',
    ma: '₁',
    da: '₂',
    ni: '₂',
    chakra: 'Indu',
    description:
      'Vanaspati means the lord of the forest and is the 4th melakarta ragam in the 72 melakarta system of Carnatic music.\r\nIn the Muthuswami Dikshitar school of music, this raga is called Bhanumati. Vanaspati is the 4th ragam in the 1st chakra Indu of the melakarta system. Its mnemonic name is Indu-Bhu. Its mnemonic phrase is sa ra ga ma pa dhi ni.',
    janyas: 'Bhānumati\r\nRasāli\r\nVanāvali\r\nVittalapriya',
    compositions: 'composition1,composition2,composition6',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXub815V5ZeFs9HcJ7r3nbhK'
  },
  {
    number: '5',
    name: 'Manavati',
    ri: '₁',
    ga: '₁',
    ma: '₁',
    da: '₂',
    ni: '₃',
    chakra: 'Indu',
    description:
      'Manavati means the bride and is the 5th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIn Muthuswami Dikshitar school of Carnatic music, the 5th melakarta is Manoranjani. It is the 5th ragam in the 1st chakra Indu. The mnemonic name is Indu-Ma. The mnemonic phrase is sa ra ga ma pa dhi nu.',
    janyas: 'Manoranjani\r\nGhanashyāmalā\r\nKunjari',
    compositions: 'composition1,composition2,composition7',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXshv5JnZgoz8XLLzBjV0m4g'
  },
  {
    number: '6',
    name: 'Tanarupi',
    ri: '₁',
    ga: '₁',
    ma: '₁',
    da: '₃',
    ni: '₃',
    chakra: 'Indu',
    description:
      'Tanarupi means the one that embodies tanam and is the 6th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Tanukeerti in Muthuswami Dikshitar school of Carnatic music. It is the 6th ragam in the 1st chakra Indu. The mnemonic name is Indu-Sha. The mnemonic phrase is sa ra ga ma pa dhu nu.',
    janyas: 'Tanukeerti',
    compositions: 'composition1,composition2,composition8',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXshDisFdvLUM7rAFgC0D6Ka'
  },
  {
    number: '7',
    name: 'Senavati',
    ri: '₁',
    ga: '₂',
    ma: '₁',
    da: '₁',
    ni: '₁',
    chakra: 'Netra',
    description:
      'Senavati is the 7th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Senagrani in Muthuswami Dikshitar school of Carnatic music. It is the 1st ragam in the 2nd chakra Netra. The mnemonic name is Netra-Pa. The mnemonic phrase is sa ra gi ma pa dha na.',
    janyas:
      'Senāgrani\r\nBhogi\r\nChitthakarshani\r\nNavarasa Mālā\r\nSindhu Gowri',
    compositions: 'composition1,composition2,composition9',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXuMMM4ZW4-sGMFmZsdKEr7o'
  },
  {
    number: '8',
    name: 'Hanumatodi',
    ri: '₁',
    ga: '₂',
    ma: '₁',
    da: '₁',
    ni: '₂',
    chakra: 'Netra',
    description:
      'Hanumatodi, more popularly known as Todi, is the 8th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is a difficult ragam to perform due to its complexity in prayoga (phrases of notes and intonation) and is often performed in concert. It is called Janatodi in Muthuswami Dikshitar school of Carnatic music. The Western equivalent of the ragam is the Phrygian mode. The mnemonic name is Netra-Sri. The mnemonic phrase is sa ra gi ma pa dha ni.',
    janyas:
      'Janatodi\r\nAmrita Dhanyāsi\r\nAsāveri\r\nBhānuchandrika\r\nBhadratodi\r\nBhoopālam\r\nChandrikatodi\r\nDeshikatodi\r\nDhanyāsi\r\nDivyamālati\r\nGhanta\r\nKalāsāveri\r\nKanakasāveri\r\nNāgavarāli\r\nPrabhupriya\r\nPunnāgatodi\r\nPunnagavarali\r\nShravanamallika\r\nSowjanya\r\nShuddha Seemantini\r\nShuddha Todi\r\nSindhubhairavi\r\nSwarnamalli',
    compositions: 'composition1,composition2,composition10',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsPEMEmffl6tqhe4nzIFUev'
  },
  {
    number: '9',
    name: 'Dhenuka',
    ri: '₁',
    ga: '₂',
    ma: '₁',
    da: '₁',
    ni: '₃',
    chakra: 'Netra',
    description:
      'Dhenuka is the 9th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Dhunibinnashadjam in Muthuswami Dikshitar school of Carnatic music. It is the 3rd ragam in the 2nd chakra Netra. The mnemonic name is Netra-Go. The mnemonic phrase is sa ra gi ma pa dha nu.',
    janyas:
      'Dhunibhinnashadjam\r\nBhinnashadjam\r\nMohananāta\r\nUdayaravichandrika\r\nVasanthatodi',
    compositions: 'composition1,composition2,composition11',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsn23XI1vt581SNe0nNHwjF'
  },
  {
    number: '10',
    name: 'Natakapriya',
    ri: '₁',
    ga: '₂',
    ma: '₁',
    da: '₂',
    ni: '₂',
    chakra: 'Netra',
    description:
      'Natakapriya means the one dear to theatre and is the 10th melakarta ragam in the 72 melakarta ragam system.\r\nAccording to the Muthuswami Dikshitar school, the 10th melakarta ragam is called Naţabharanam. It is the 4th ragam in the 2nd chakra Netra. The mnemonic name is Netra-Bhu. The mnemonic phrase is sa ra gi ma pa dhi ni.',
    janyas:
      'Natābharanam\r\nAlankārapriya\r\nBhāgyashabari\r\nDeeparamu\r\nGunāvati\r\nHindoladeshikam\r\nKanakadri\r\nMātangakāmini\r\nNātyadhārana\r\nNiranjana\r\nShānthabhāshini\r\nShivashakti',
    compositions: 'composition1,composition2,composition12',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXu0q_fDcI4dAsL_3doG4B3x'
  },
  {
    number: '11',
    name: 'Kokilapriya',
    ri: '₁',
    ga: '₂',
    ma: '₁',
    da: '₂',
    ni: '₃',
    chakra: 'Netra',
    description:
      'Kokilapriya means the one dear to the koel and is the 11th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIn the Muthuswami Dikshitar school of Carnatic music, the ragam is called Kokilaravam. It is the 5th ragam in the 2nd chakra Netra. The mnemonic name is Netra-Ma. The mnemonic phrase is sa ra gi ma pa dha na.',
    janyas:
      'Kokilāravam\r\nChitramani\r\nJnānachintāmani\r\nKowmāri\r\nShuddha Lalitha\r\nVardhani\r\nVasantamalli\r\nVasantanārāyani',
    compositions: 'composition1,composition2,composition13',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsa_cJkbdeqSIIk4oI-qofP'
  },
  {
    number: '12',
    name: 'Rupavati',
    ri: '₁',
    ga: '₂',
    ma: '₁',
    da: '₃',
    ni: '₃',
    chakra: 'Netra',
    description:
      'Rupavati means the beautiful one and is the 12th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is one of the few ragams given the same name by the Muthuswami Dikshitar school of Carnatic music. It is the 6th ragam in the 2nd chakra Netra. The mnemonic name is Netra-Sha. The mnemonic phrase is sa ra gi ma pa dhu nu.',
    janyas: 'Rowpyanaka\r\nShyāmakalyāni',
    compositions: 'composition1,composition2,composition14',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsMYdcYDrRHJxGLkMatOVTt'
  },
  {
    number: '13',
    name: 'Gayakapriya',
    ri: '₁',
    ga: '₃',
    ma: '₁',
    da: '₁',
    ni: '₁',
    chakra: 'Agni',
    description:
      'Gayakapriya is the 13th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Geyahejjujji in Muthuswami Dikshitar school of Carnatic music. It is the 1st ragam in the 3rd chakra Agni. The mnemonic name is Agni-Pa. The mnemonic phrase is sa ra gu ma pa dha na.',
    janyas:
      'Geya Hejjajji\r\nHejjajji\r\nKalākānti\r\nKalkada\r\nKalpanadhārini',
    compositions: 'composition1,composition2,composition15',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsKwXCUcLgbSjFMuoyGHUHn'
  },
  {
    number: '14',
    name: 'Vakulabharanam',
    ri: '₁',
    ga: '₃',
    ma: '₁',
    da: '₁',
    ni: '₂',
    chakra: 'Agni',
    description:
      'Vakulabharanam is the 14th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Dhativasantabhairavi or Vativasantabhairavi in Muthuswami Dikshitar school of Carnatic music. It is the 2nd ragam in the 3rd chakra Agni. The mnemonic name is Agni-Sri. The mnemonic phrase is sa ra gu ma pa dha ni.',
    janyas:
      'Vātee Vasantabhairavi\r\nĀhiri\r\nAmudhasurabhi\r\nDevipriya\r\nKalindaja\r\nKuvalayabharanam\r\nSallapa\r\nSoma\r\nSūryā\r\nShuddha Kāmbhoji\r\nVasantabhairavi\r\nVasanta Mukhāri\r\nVijayollāsini',
    compositions: 'composition1,composition2,composition16',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXtOWChB7duFGN0Gcp0mQnBg'
  },
  {
    number: '15',
    name: 'Mayamalavagowla',
    ri: '₁',
    ga: '₃',
    ma: '₁',
    da: '₁',
    ni: '₃',
    chakra: 'Agni',
    description:
      'Mayamalavagowla is an auspicious raga that evokes peace and pathos and is the 15th melakarta raga in the 72 melakarta ragam system of Carnatic music.\r\nIt creates a soothing effect and is suitable to sing at all times, particularly at dawn. It is commonly used for beginner lessons as it has a uniform distance between the position of notes and has symmetry, making it easier to learn. It is an ancient ragam and has been around for a long time.Originally it was known as malavagowla. Maya was prefixed to the name after the advent of the scheme of the 72 melas. It is a morning ragam. Mayamalavagowla is the 3rd raga in the 3rd chakra, Agni. Its mnemonic name is Agni-Go. Its mnemonic phrase is sa ra gu ma pa dha nu.\r\nAll the basic swara exercises (Sarali, Janta, Daattu, etc.) are set to the Mayamalavagowla raga. These are learnt by beginners in Carnatic Music. Lambodara lakumikara by Purandaradasa is generally used for this raga. The first mini-songs (geetam) that are taught after the completion of basic exercises, are set to Malahari, a janya of Mayamalavagowla.',
    janyas:
      'Ardhradesi\r\nBhāvini\r\nBibhās\r\nBowli\r\nBowli Rāmakriya\r\nChāruvardhani\r\nChāyagowla\r\nChandrachooda\r\nDeshyagowla\r\nDevaranji\r\nEkakshari\r\nGhanasindhu\r\nGowla\r\nGowlipantu\r\nGowri\r\nGummakāmbhoji\r\nGundakriya\r\nGurjari\r\nJaganmohini\r\nKalyānakesari\r\nKannadabangāla\r\nKarnātaka Sāranga\r\nLalitā\r\nLalitapanchamam\r\nMālavakurinji\r\nMālavapanchamam\r\nMārgadesi\r\nMalahari\r\nMallikavasantam\r\nMangalakaishiki\r\nManolayam\r\nMaruva\r\nMechabowli\r\nMegharanjani\r\nNadanamakriya\r\nPādi\r\nPharaju (Paras)\r\nPoornalalita\r\nPoorvi\r\nPoorvikavasanta\r\nPratāpadhanyāsi\r\nPratāparanjani\r\nPuranirmai\r\nRāmakali\r\nRāmakriya\r\nRevagupti\r\nRukhmāmbari\r\nSāmantadeepara\r\nSāranga Nāta\r\nSāveri\r\nSalanganāta\r\nSatyāvati\r\nSindhu Rāmakriya\r\nSurasindhu\r\nTārakagowla\r\nTakka\r\nUshāvali\r\nVishārada',
    compositions: 'composition1,composition2,composition17',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXteen6e9D0Dh1jqZplgSFQr'
  },
  {
    number: '16',
    name: 'Chakravakam',
    ri: '₁',
    ga: '₃',
    ma: '₁',
    da: '₂',
    ni: '₂',
    chakra: 'Agni',
    description:
      'Chakravakam (or Chakravaham) is a raga that is known to incite feelings of devotion, sympathy and compassion in the listeners and is the 16th Melakarta ragam in the 72 melakarta ragam system.\r\nAccording to the Muthuswami Dikshitar school, this ragam is called Toyavegavahini. It is the 4th melakarta in the 3rd chakra- Agni. The mnemonic name is Agni-Bhu. The mnemonic phrase is sa ra gu ma pa dhi ni.',
    janyas:
      'Toyavegavāhini\r\nĀhir Bhairavi\r\nBhakthapriya\r\nBhujāngini\r\nBindhumālini\r\nChakranārāyani\r\nGhoshini\r\nGuhapriya\r\nKalāvati\r\nKokilā\r\nMalayamārutam\r\nMukthāngi\r\nMukundamālini\r\nPoornapanchamam\r\nPravritti\r\nRāgamanjari\r\nRasikaranjani\r\nRudra Panchami\r\nShree Nabhomārgini\r\nShyāmali\r\nSubhāshini\r\nValaji\r\nVeenadhāri\r\nVegavāhini',
    compositions: 'composition1,composition2,composition18',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsZfhX2WLKE0aBv0Az8mgb9'
  },
  {
    number: '17',
    name: 'Suryakantam',
    ri: '₁',
    ga: '₃',
    ma: '₁',
    da: '₂',
    ni: '₃',
    chakra: 'Agni',
    description:
      'Suryakantam is the 17th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Chayavati in Muthuswami Dikshitar school of Carnatic music. It is the 5th ragam in the 3rd chakra Agni. The mnemonic name is Agni-Ma. The mnemonic phrase is sa ra gu ma pa dhi nu.',
    janyas:
      'Chāyāvathi\r\nBhairavam\r\nHaridarpa\r\nJayasamvardhani\r\nJeevantikā\r\nKusumamāruta\r\nNāgachooḍāmani\r\nRohini\r\nSāmakannada\r\nSowrāshtram\r\nSuddha Gowla\r\nSupradeepam\r\nVasanthā',
    compositions: 'composition1,composition2,composition19',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXt4xUt-3jYfg33HFeEoTNWw'
  },
  {
    number: '18',
    name: 'Hatakambari',
    ri: '₁',
    ga: '₃',
    ma: '₁',
    da: '₃',
    ni: '₃',
    chakra: 'Agni',
    description:
      'Hatakambari is the 18th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Jayashuddhamalavi in Muthuswami Dikshitar school of Carnatic music. It is the 6th ragam in the 3rd chakra Agni. The mnemonic name is Agni-Sha. The mnemonic phrase is sa ra gu ma pa dhu nu.',
    janyas: 'Jayashuddhamālavi\r\nHamsanantini\r\nKallola\r\nSimhala',
    compositions: 'composition1,composition2,composition20',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXuWKuDa_QBldC_9QBA-5GTl'
  },
  {
    number: '19',
    name: 'Jhankaradhwani',
    ri: '₂',
    ga: '₂',
    ma: '₁',
    da: '₁',
    ni: '₁',
    chakra: 'Veda',
    description:
      'Jhankaradhvani is the 19th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Jhankarabhramari in Muthuswami Dikshitar school of Carnatic music. It is the 1st ragam in the 4th chakra Veda. The mnemonic name is Veda-Pa. The mnemonic phrase is sa ri gi ma pa dha na.',
    janyas:
      'Jhankārabhramari\r\nBhārati\r\nChittaranjani\r\nJalmika\r\nLalitabhairavi\r\nPoornalalita',
    compositions: 'composition1,composition2,composition21',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXvR3MJV3IgZ_xKfJyRjAM-J'
  },
  {
    number: '20',
    name: 'Natabhairavi',
    ri: '₂',
    ga: '₂',
    ma: '₁',
    da: '₁',
    ni: '₂',
    chakra: 'Veda',
    description:
      'Natabhairavi is the 20th melakarta ragam in the 72 melakarta ragam system.\r\nIt corresponds to the natural minor scale of western music system. In the Muthuswami Dikshitar school this ragam is called Nariritigowla. Natabhairavi is known to be a ragam that incites feelings of grandeur and devotion in the listeners. It is the 2nd ragam in the 4th chakra Veda. The mnemonic name is Veda-Sri. The mnemonic phrase is sa ri gi ma pa dha ni.',
    janyas:
      'Nārērētigowla\r\nAbheri (Dikshitar School)\r\nAnandabhairavi\r\nAmrithavāhini\r\nBhairavi\r\nBhuvanagāndhāri\r\nChapagantarva\r\nDarbāri Kānada\r\nDevakriya\r\nDhanashree\r\nDharmaprakāshini\r\nDilipika Vasantha\r\nDivyagāndhāri\r\nGopikāvasantam\r\nHindolam\r\nHindolavasanta\r\nIndughantarava\r\nJayanthashrī\r\nJingaḷa\r\nJonpuri\r\nKātyāyani\r\nKanakavasantham\r\nKshanika\r\nMānji\r\nMahati cf. Mela 28&43 (Mahathi)\r\nMalkosh\r\nmArgahindOLaM\r\nNāgagāndhāri\r\nNavarathna Vilāsam\r\nNīlamati\r\nNīlaveni\r\nPoornashađjam\r\nRājarājeshwari\r\nSāramati\r\nSāranga Kāpi\r\nSharadapriya\r\nShree Navarasachandrika\r\nSindhu Dhanyāsi\r\nShuddha Desi\r\nShuddha Sālavi\r\nSukumāri\r\nSushama\r\nSutradhāri\r\nTarkshika\r\nUdayarāga\r\nVasantavarāli',
    compositions: 'composition1,composition2,composition22',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXvu1FznqKWJbfyW9ba9tP2N'
  },
  {
    number: '21',
    name: 'Keeravani',
    ri: '₂',
    ga: '₂',
    ma: '₁',
    da: '₁',
    ni: '₃',
    chakra: 'Veda',
    description:
      'Keeravani is the 21st Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Kiraṇavaḻi in Muthuswami Dikshitar school of Carnatic music.\r\nThis ragam is a popular scale in western music as well as the harmonic minor scale. It is the 3rd ragam in the 4th chakra Veda. The mnemonic name is Veda-Go. The mnemonic phrase is sa ri gi ma pa dha nu.',
    janyas:
      'Keeranāvali\r\nAymmukhan\r\nBhānupriya\r\nChandrika\r\nGaganabhoopālam\r\nHamsapancama\r\nHamsavāhini\r\nJayashree\r\nKadaram (Chandrakosh, Chandrakauns, Chandrakaush)\r\nKalyāna Vasantam\r\nKusumāvali\r\nMādhavi\r\nMishramanolayam\r\nPriyadarshani\r\nRishipriya\r\nSāmapriya\r\nShrothasvini\r\nVasanthamanohari',
    compositions: 'composition1,composition2,composition23',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXu92qvldyjBMpfJemyEsivZ'
  },
  {
    number: '22',
    name: 'Kharaharapriya',
    ri: '₂',
    ga: '₂',
    ma: '₁',
    da: '₂',
    ni: '₂',
    chakra: 'Veda',
    description:
      'Kharaharapriya is the 22nd melakarta raga in the 72 melakarta raga system.\r\nIt is possible that the name of the ragam was originally Harapriya but it was changed to conform to the Katapayadi formula. Kharaharapriya has a distinct melody and evokes pathos in the listeners. The western equivalent of the ragam is the Dorian mode.\r\nThere are many theories behind the etymology of the name Kharaharapriya. One of the most popular beliefs is that the ragam was initially called Samaganam and when Ravana was trapped by Shiva, Ravana sang many hymns in praise, but Shiva only cooled down when a hymn was sung in the ragam and hence Harapriya, the one dear to Shiva. Alternatively, the word Kharaharapriya may also mean the beloved of the killer of the Khara demon (Khara - Khara demon, Hara - Defeater/Killer, Priya - Beloved). The story of Rama killing the Khara demon is narrated in the Ramayana. \r\nIt is the 4th raga in the 4th chakra Veda. The mnemonic name is Veda-Bhu. The mnemonic phrase is sa ri gi ma pa dhi ni. Since the swaras of Kharaharapriya are quite evenly spaced, and since several different types of gamakas are allowed, it is a very versatile, fluid and flexible ragam that allows for elaborate melodic improvisation within its scale. Songs sung in Kharaharapriya ragas typically have long, elaborate alapanas, which exhibit the fluidity of the ragam. Kharaharapriya songs are usually meant to be sung slow, medium or medium-fast, in order to bring out the qualities of the ragam.',
    janyas:
      'Shree\r\nAndolikā\r\nAbheri\r\nAbhogi\r\nĀdi Kāpi\r\nĀryamati\r\nAgnikopa\r\nBālachandrika\r\nBasant Bahār\r\nBhāgeshri\r\nBhagavatapriya\r\nBhagavathpriya\r\nBhimpalās\r\nBrindāvana Sāranga\r\nBrindāvani\r\nChakrapradipta\r\nChittaranjani\r\nDarbar\r\nDayavati\r\nDevāmruthavarshani\r\nDeva Manohari\r\nDhanakāpi\r\nDilipika\r\nDurga\r\nGowla Kannada\r\nHamsa ābheri\r\nHaridasapriya\r\nHarinārāyani\r\nHindustāni Kāpi\r\nHuseni\r\nJatādhāri\r\nJayamanohari\r\nJayanārāyani\r\nJayanthasena\r\nKanadā\r\nKāpi\r\nKāpijingala\r\nKalānidhi\r\nKalika\r\nKannadagowla\r\nKarnātaka Hindolam\r\nKarnātaka Kāpi\r\nKarnātaka Devagāndhari\r\nKarnaranjani\r\nKowmodaki\r\nKowshika\r\nLalitamanohari\r\nMādhavamanohari\r\nMālavashree\r\nMāyapradeeptam\r\nMadhyamarāvali\r\nMadhyamāvathi\r\nMahānandhi\r\nMandāmari\r\nMangalāvathi\r\nManirangu\r\nManjari\r\nManohari\r\nManorama\r\nMaruvadhanyāsi\r\nMishramanolayam\r\nMishrashivaranjani\r\nMiyan Malhār\r\nMukhāri\r\nNādachintāmani\r\nNādatārangini\r\nNādavarangini\r\nNāgari\r\nNāgavalli\r\nNāyaki\r\nNigamagāmini\r\nNirmalāngi\r\nOmkāri\r\nPanchamam\r\nPatadeep\r\nPhalamanjari\r\nPhalaranjani\r\nPeeloo\r\nPoornakalānidhi\r\nPushpalathika\r\nRatipatipriya\r\nReethigowla\r\nRudrapriyā\r\nSālagabhairavi\r\nSārang\r\nSaindhavi\r\nSangrama\r\nSankrāndanapriyā\r\nSarvachoodāmani\r\nShivapriyā\r\nShivaranjani\r\nShree Manohari\r\nShree Manoranjani\r\nShreeranjani\r\nSiddhasena\r\nSuddha Bangāla\r\nSuddha Bhairavi\r\nSuddha Dhanyāsi\r\nSuddha Hindolam (Varamu)\r\nSuddha Manohari\r\nSuddha Velāvali\r\nSugunabhooshani\r\nSwarabhooshani\r\nSwarakalānidhi\r\nSwararanjani\r\nTavamukhāri\r\nVajrakānti',
    compositions: 'composition1,composition2,composition24',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXtwrYfCjWd_wz1N4yBbRXXP'
  },
  {
    number: '23',
    name: 'Gourimanohari',
    ri: '₂',
    ga: '₂',
    ma: '₁',
    da: '₂',
    ni: '₃',
    chakra: 'Veda',
    description:
      'Gourimanohari is the 23rd Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIn the Muthuswami Dikshitar school of music, the raga is known as Gourivelavaḻi. The Western equivalent is the melodic minor scale. It is the 5th ragam in the 4th chakra Veda. The mnemonic name is Veda-Ma. The mnemonic phrase is sa ri gi ma pa dhi nu.',
    janyas:
      'Gowrivelāvali\r\nGowrishankar\r\nHamsadeepika\r\nHrudkamali\r\nLavanthika\r\nSundaramanohari\r\nVasantashree (Amba Manohari)\r\nVelāvali',
    compositions: 'composition1,composition2,composition25',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXtG0skCDoEzkAEzTYJqIBHe'
  },
  {
    number: '24',
    name: 'Varunapriya',
    ri: '₂',
    ga: '₂',
    ma: '₁',
    da: '₃',
    ni: '₃',
    chakra: 'Veda',
    description:
      'Varunapriya is the 24th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Viravasantam in Muthuswami Dikshitar school of Carnatic music. It is the 6th ragam in the 4th chakra Veda. The mnemonic name is Veda-Sha. The mnemonic phrase is sa ri gi ma pa dhu nu.',
    janyas: 'Veeravasantham',
    compositions: 'composition1,composition2,composition26',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXvAnVkwmz70MYPz72DLCK9n'
  },
  {
    number: '25',
    name: 'Mararanjani',
    ri: '₂',
    ga: '₃',
    ma: '₁',
    da: '₁',
    ni: '₁',
    chakra: 'Bana',
    description:
      'Mararanjani is the 25th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Sharavati in Muthuswami Dikshitar school of Carnatic music. It is the 1st ragam in the 5th chakra Bana. The mnemonic name is Bana-Pa. The mnemonic phrase is sa ri gu ma pa dha na.',
    janyas: 'Sharāvathi\r\nDevasalaga\r\nKesari\r\nRājathilaka',
    compositions: 'composition1,composition2,composition27',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXv560M3COTSAKXhGsuMBBsR'
  },
  {
    number: '26',
    name: 'Charukesi',
    ri: '₂',
    ga: '₃',
    ma: '₁',
    da: '₁',
    ni: '₂',
    chakra: 'Bana',
    description:
      'Charukesi is the 26th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Tarangini in Muthuswami Dikshitar school of Carnatic music. Charukesi is known to incite feelings of pathos and devotion in the listener. It is the 2nd ragam in the 5th chakra Bana. The mnemonic name is Bana-Sri. The mnemonic phrase is sa ri gu ma pa dha ni.',
    janyas:
      'Tarangini\r\nChirswaroopi\r\nMāravi\r\nPoorvadhanyāsi\r\nShiva Manohari\r\nShukrajyothi\r\nUshābharanam',
    compositions: 'composition1,composition2,composition28',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXt7PXchDkYA6bbgi-vzFlbN'
  },
  {
    number: '27',
    name: 'Sarasangi',
    ri: '₂',
    ga: '₃',
    ma: '₁',
    da: '₁',
    ni: '₃',
    chakra: 'Bana',
    description:
      'Sarasangi is the 27th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Sowrasena in Muthuswami Dikshitar school of Carnatic music. In Western music it is known as the Harmonic major scale. It is the 3rd ragam in the 5th chakra Bana. The mnemonic name is Bana-Go. The mnemonic phrase is sa ri gu ma pa dha ni.',
    janyas:
      'Sowrasenā\r\nHaripriya\r\nKamalā Manohari\r\nMadhulika\r\nNalinakānthi\r\nNeelamani\r\nSalavi\r\nSarasānana\r\nSaraseeruha\r\nSimhavāhini\r\nSurasena\r\nSutradhāri\r\nVasanthi',
    compositions: 'composition1,composition2,composition29',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXv3GJ4NllbtDQFAFW5MVu6Y'
  },
  {
    number: '28',
    name: 'Harikambhoji',
    ri: '₂',
    ga: '₃',
    ma: '₁',
    da: '₂',
    ni: '₂',
    chakra: 'Bana',
    description:
      'Harikambhoji is the 28th Melakarta ragam in the 72 melakarta ragam system.\r\nOne of the first scales employed by the ancient Tamils (3BCE) was the Mullaipann, a pentatonic scale composed of the notes sa ri ga pa da, equivalent to C, D, E, G and A in the western notations. These fully harmonic scales make up the raga Mohanam in Carnatic music. Mullaipann further evolved into Sempaalai, a scale based on seven notes by the addition of two more notes, ma and ni to the pentatonic scale which corresponds to the Carnatic raga Harikambhoji.\r\nThe raga is known as Harikedaragowla in the Muthuswami Dikshitar school of music. In Western music, Mixolydian mode is the equivalent of the raga. It is the 4th ragam in the 5th chakra Bana. The mnemonic name is Bana-Bhu. The mnemonic phrase is sa ri ga ma pa dha ni sa.',
    janyas:
      'Harikedāragowla\r\nAmbhojini\r\nAndhali\r\nAparoopam\r\nBālahamsa\r\nBahudāri\r\nBhoopāli\r\nChāyalagakhamās\r\nChāyatārangini\r\nChandrahasitham\r\nDasharatipriya\r\nDayaranjani\r\nDesh\r\nDeshākshi\r\nDeshkār\r\nDwaithachintāmani\r\nDwijāvanthi\r\nEeshamanohari\r\nEeshaivaridhi\r\nGāndhāralola\r\nGavathi\r\nGuhamanohari\r\nGuharanjani\r\nHamsaroopini\r\nHaridasapriya\r\nHarikedāram\r\nHarini\r\nHarithapriya\r\nHemasāranga\r\nJaijaivanthi\r\nJaithshree\r\nJana Sammodhini\r\nJayarāma\r\nJhinjothi\r\nJog (Hindustani)\r\nJujahuli\r\nKāmbhoji\r\nKāpi Nārāyani\r\nKamās\r\nKarnātaka Behāg\r\nKarnātaka Devagāndhāri\r\nKarnātaka Khamās\r\nKedāragowla\r\nKeshavapriyā\r\nKokiladhwani\r\nKokilavarāli\r\nKunthalavarāli\r\nMālavi\r\nMadhurakokila\r\nMahathi\r\nMahuri\r\nManjupriya\r\nManoharam\r\nMattakokila\r\nMeghana\r\nMohanam\r\nNādavalli\r\nNāgaswarāvali\r\nNārāyanagowla\r\nNārāyani\r\nNāttai Kurinji\r\nNāttai Nārāyani\r\nNandhkowns\r\nNarani\r\nNavarasa Kalānidhi\r\nNavarasa kannada\r\nNeela\r\nPārsi\r\nParameshwarapriyā\r\nPashupathipriyā\r\nPoornakāmbhoji\r\nPratāpa Nāttai\r\nPratāpavarāli\r\nPravalajyoti\r\nRāgapanjaramu\r\nRāgavinodini\r\nRāgeshree\r\nRavi Chandrikā\r\nSāvithri\r\nSahāna\r\nSaraswathi Manohari\r\nSathvamanjari\r\nShakunthala\r\nShankaraharigowla\r\nShenchukāmbhoji\r\nChenjurutti\r\nShiva Kāmbhoji\r\nSurutti\r\nShyāmā\r\nSimhavikrama\r\nSindhu Kannada\r\nSindhu Surutti\r\nSuddha Khamās\r\nSuddha Varāli\r\nSuddha\r\nSuddhatarangini\r\nSumanapriyā\r\nSuposhini\r\nSuvarnakriyā\r\nSwarāvali\r\nSwaravedi\r\nTilakavathi\r\nThilang\r\nUmābharanam\r\nVaishnavi\r\nVeenavadini\r\nVivardhani\r\nYadukula Kāmbhoji',
    compositions: 'composition1,composition2,composition30',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXs3BCmfNPKNBQoJa_RR5N_o'
  },
  {
    number: '29',
    name: 'Dheerasankarabaranam',
    ri: '₂',
    ga: '₃',
    ma: '₁',
    da: '₂',
    ni: '₃',
    chakra: 'Bana',
    description:
      'Dhirasankarabharanam, commonly known as Sankarabharanam, is the 29th Melakarta raga in the 72 Melakarta raga system of Carnatic music.\r\nThis raga has many Gamakas (ornamentations) and the western equivalent is the major scale, or the Ionian mode. Hence this raga is one of the most popular scales across the world, known with different names in different musical styles. The nature of the raga is mellifluous and smooth and it offers a large scope for compositions. It is ideal for a melodious, but still laid back, majestic presentation.\r\nIt is the 5th raga in the 5th Chakra Baṇa. The mnemonic name is Bana-Ma. The mnemonic phrase is sa ri ga ma pa da ni sa. Due to the even spacing of svaras, many janya ragas can be derived from Sankarabharanam. Many of the Janya ragas are very popular on their own, lending themselves to elaboration, interpretation and evoking different moods. ',
    janyas:
      'Ānandharoopa\r\nĀrabhi\r\nAtāna\r\nBangāla\r\nBegada\r\nBehāg\r\nBehāg Deshikam\r\nBilahari\r\nBuddhamanohari\r\nBuddharanjani\r\nChāyā\r\nChāyashankarābharanam\r\nDevagāndhāri\r\nDharmalakhi\r\nDhurvanki\r\nGajagowri\r\nGarudadhvani\r\nGowdamalhār\r\nHamsadhwani\r\nHamsavinodhini\r\nHemant\r\nHindustāni Behāg\r\nJana Ranjani\r\nJulavu\r\nKamaripriyā\r\nKannada\r\nKadanakuthoohalam\r\nKedaram\r\nKokilabhāshani\r\nKolahalam\r\nKurinji\r\nKusumavichithra\r\nKutuhala\r\nLahari\r\nMānd\r\nMāyadravila\r\nMohanadhwani\r\nNāgabhooshani\r\nNāgadhwani\r\nNārāyanadeshākshi\r\nNavaroj\r\nNeelāmbari\r\nNiroshta\r\nPahādi\r\nPoornachandrika\r\nPoornagowla\r\nPoorvagowla\r\nRathnabhooshani\r\nReetuvilāsa\r\nSāranga Mallār\r\nShankara\r\nShankaraharigowla\r\nShankaramohana\r\nShankari\r\nSindhu\r\nSindhu Mandāri\r\nSuddha Mālavi\r\nSuddha Sārang\r\nSuddha Sāveri\r\nSuddha Vasantha\r\nSuranandini\r\nSuraranjani\r\nTāndavam\r\nVallabhi\r\nVasanthamalai\r\nVedhāndhagamana\r\nVeerapratāpa\r\nVilāsini',
    compositions: 'composition1,composition2,composition31',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXuO-2F_9XWK5YaK0Gcj4kZI'
  },
  {
    number: '30',
    name: 'Naganandini',
    ri: '₂',
    ga: '₃',
    ma: '₁',
    da: '₃',
    ni: '₃',
    chakra: 'Bana',
    description:
      'Naganandini is the 30th Melakarta ragam in the 72 melakarta ragam system of Carnatic music and is named after the goddess Parvati. \r\nIt is called Nagabharanam in Muthuswami Dikshitar school of Carnatic music. It is the 6th ragam in the 5th chakra Bana. The mnemonic name is Bana-Sha. The mnemonic phrase is sa ri gu ma pa dhu nu.',
    janyas:
      'Nāgabharanam\r\nGambheeravani\r\nLalithagāndharva\r\nSāmanta\r\nThilang',
    compositions: 'composition1,composition2,composition32',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsOhBJfwKDMLn8G2ttZaEQp'
  },
  {
    number: '31',
    name: 'Yagapriya',
    ri: '₃',
    ga: '₃',
    ma: '₁',
    da: '₁',
    ni: '₁',
    chakra: 'Rutu',
    description:
      'Yagapriya is the 31st melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Kalavati in Muthuswami Dikshitar school of Carnatic music. It is the 1st ragam in the 6th chakra Rutu. The mnemonic name is Rutu-Pa. The mnemonic phrase is sa ru gu ma pa dha na.',
    janyas:
      'Kalāvathi\r\nDamarugapriya\r\nDesharanjani\r\nDeshyathodi\r\nKalāhamsa\r\nNiranjani\r\nPrathāpahamsi',
    compositions: 'composition1,composition2,composition33',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXv7J5WhTKRh5dvXNJBKb14x'
  },
  {
    number: '32',
    name: 'Ragavardhini',
    ri: '₃',
    ga: '₃',
    ma: '₁',
    da: '₁',
    ni: '₂',
    chakra: 'Rutu',
    description:
      'Ragavardhini is the 32nd melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Ragachudamaṇi in Muthuswami Dikshitar school of Carnatic music. It is the 2nd ragam in the 6th chakra Ritu. The mnemonic name is Ritu-Sri. The mnemonic phrase is sa ru gu ma pa dha ni.',
    janyas:
      'Rāgachoodāmani\r\nAmudagāndhāri\r\nDhowmya\r\nHindoladarbār\r\nRamyā\r\nSāmantajingala',
    compositions: 'composition1,composition2,composition34',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXuN86bhUA1k1nZUay6EiU0H'
  },
  {
    number: '33',
    name: 'Gangeyabhushani',
    ri: '₃',
    ga: '₃',
    ma: '₁',
    da: '₁',
    ni: '₃',
    chakra: 'Rutu',
    description:
      'Gangeyabhushani is the 33rd Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Gangatarangini in Muthuswami Dikshitar school of Carnatic music. It is the 3rd ragam in the 6th chakra Rutu. The mnemonic name is Rutu-Go. The mnemonic phrase is sa ru gu ma pa dha nu.',
    janyas: 'Gangātarangini',
    compositions: 'composition1,composition2,composition35',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXs6YLkQRqi9lnn0Lo5eCnwb'
  },
  {
    number: '34',
    name: 'Vagadheeswari',
    ri: '₃',
    ga: '₃',
    ma: '₁',
    da: '₂',
    ni: '₂',
    chakra: 'Rutu',
    description:
      'Vagadheeswari is the 34th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Bhogachhayanata in Muthuswami Dikshitar school of Carnatic music. It is the 4th ragam in the 6th chakra Rutu. The mnemonic name is Rutu-Bhu. The mnemonic phrase is sa ru gu ma pa dhi ni.',
    janyas:
      'Bhogachāyā Nāttai\r\nBhānumanjari\r\nChāyanāttai\r\nMaghathi\r\nDrāvida Kalāvati\r\nMohanāngi\r\nMurali\r\nSharadabharana\r\nVikhavathi',
    compositions: 'composition1,composition2,composition36',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsP0IWStVsVP258TZQrd4Vt'
  },
  {
    number: '35',
    name: 'Shulini',
    ri: '₃',
    ga: '₃',
    ma: '₁',
    da: '₂',
    ni: '₃',
    chakra: 'Rutu',
    description:
      'Shulini is the 35th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Shailadesakshi or Shailadaeshi in Muthuswami Dikshitar school of Carnatic music. It is the 5th ragam in the 6th chakra Rutu. The mnemonic name is Rutu-Ma. The mnemonic phrase is sa ru gu ma pa dhi nu.',
    janyas: 'Shailadeshākshhi\r\nDheerahindolam\r\nGanavaridhi\r\nShokavarāli',
    compositions: 'composition1,composition2,composition37',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXviFThgiSk7xdtsNY0lxUmZ'
  },
  {
    number: '36',
    name: 'Chalanata',
    ri: '₃',
    ga: '₃',
    ma: '₁',
    da: '₃',
    ni: '₃',
    chakra: 'Rutu',
    description:
      'Chalanata is the 36th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is one of the few ragams referred to by the same name in Muthuswami Dikshitar school of Carnatic music. It is the 6th ragam in the 6th chakra Rutu. The mnemonic name is Rutu-Sha. The mnemonic phrase is sa ru gu ma pa dhu nu.',
    janyas: 'Devanāttai\r\nGambheeranāttai\r\nGanaranjani\r\nNāttai',
    compositions: 'composition1,composition2,composition38',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXvUYoM6f-SWV05rSyZWS-si'
  },
  {
    number: '37',
    name: 'Salagam',
    ri: '₁',
    ga: '₁',
    ma: '₂',
    da: '₁',
    ni: '₁',
    chakra: 'Rishi',
    description:
      'Salagam is the 37th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Sowgandini in Muthuswami Dikshitar school of Carnatic music. It is the 1st ragam in the 7th chakra Rishi. The mnemonic name is Rishi-Pa. The mnemonic phrase is sa ra ga mi pa dha na.',
    janyas: 'Sowgandhini\r\nBhogasāveri',
    compositions: 'composition1,composition2,composition39',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXtXoYByZCdaWfpgZ8Zx-BY6'
  },
  {
    number: '38',
    name: 'Jalarnavam',
    ri: '₁',
    ga: '₁',
    ma: '₂',
    da: '₁',
    ni: '₂',
    chakra: 'Rishi',
    description:
      'Jalarnavam means the ocean and is the 38th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Jaganmohanam in Muthuswami Dikshitar school of Carnatic music. It is the 2nd ragam in the 7th chakra Rishi. The mnemonic name is Rishi-Sri. The mnemonic phrase is sa ra ga mi pa dha ni.',
    janyas: 'Jaganmohinam\r\nJaganmohana',
    compositions: 'composition1,composition2,composition40',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsFZ71i-YVq_nJPYIRpi1pU'
  },
  {
    number: '39',
    name: 'Jhalavarali',
    ri: '₁',
    ga: '₁',
    ma: '₂',
    da: '₁',
    ni: '₃',
    chakra: 'Rishi',
    description:
      'Jhalavarali means a moon with the heat of the sun and is the 39th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Dhalivaraḻi in Muthuswami Dikshitar school of Carnatic music. It is the 3rd ragam in the 7th chakra Rishi. The mnemonic name is Rishi-Go. The mnemonic phrase is sa ra ga mi pa dha nu.',
    janyas:
      'Dhālivarāli\r\nBhoopālapanchamam\r\nGodari\r\nJālasugandhi\r\nJanāvali\r\nKarunāmritavarshini\r\nKokilapanchamam\r\nVarāli',
    compositions: 'composition1,composition2,composition41',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXuVvwBSjytJYVjMjNdBkKYA'
  },
  {
    number: '40',
    name: 'Navaneetam',
    ri: '₁',
    ga: '₁',
    ma: '₂',
    da: '₂',
    ni: '₂',
    chakra: 'Rishi',
    description:
      'Navaneetam means the eternal new one and is the 40th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Nabhomaṇi in Muthuswami Dikshitar school of Carnatic music. It is the 4th ragam in the 7th chakra Rishi. The mnemonic name is Rishi-Bhu. The mnemonic phrase is sa ra ga mi pa dhi ni.',
    janyas: 'Nabhomani',
    compositions: 'composition1,composition2,composition42',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsRVvR6lTLkWgLfYJBGguWd'
  },
  {
    number: '41',
    name: 'Pavani',
    ri: '₁',
    ga: '₁',
    ma: '₂',
    da: '₂',
    ni: '₃',
    chakra: 'Rishi',
    description:
      'Pavani means the purifier and is the 41st melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Kumbhini in Muthuswami Dikshitar school of Carnatic music. It is the 5th ragam in the 7th chakra Rishi. The mnemonic name is Rishi-Ma. The mnemonic phrase is sa ra ga mi pa dhi nu.',
    janyas:
      'Kumbhini\r\nChandrajyothi\r\nPrabhāvali\r\nPoornalalitha\r\nPoornapanchamam',
    compositions: 'composition1,composition2,composition43',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXuEehRzyDQuINPDqOfP-mpr'
  },
  {
    number: '42',
    name: 'Raghupriya',
    ri: '₁',
    ga: '₁',
    ma: '₂',
    da: '₃',
    ni: '₃',
    chakra: 'Rishi',
    description:
      'Raghupriya means the one dear to Raghu and is the 42nd melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Ravikriya in Muthuswami Dikshitar school of Carnatic music. It is the 6th ragam in the 7th chakra Rishi. The mnemonic name is Rishi-Sha. The mnemonic phrase is sa ra ga mi pa dhu nu.',
    janyas: 'Ravi Kriyā\r\nGāndharva\r\nGomathi\r\nRaghuleela',
    compositions: 'composition1,composition2,composition44',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsfiyAF0KN_m0o02xTnjyDV'
  },
  {
    number: '43',
    name: 'Gavambhodi',
    ri: '₁',
    ga: '₂',
    ma: '₂',
    da: '₁',
    ni: '₁',
    chakra: 'Vasu',
    description:
      'Gavambodhi means the teacher of the cows and is the 43rd Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Geervaṇi or Girvaṇi in Muthuswami Dikshitar school of Carnatic music. It is the 1st ragam in the 8th chakra Vasu. The mnemonic name is Vasu-Pa. The mnemonic phrase is sa ra gi mi pa dha na.',
    janyas:
      'Geervāni\r\nKanchanabowli\r\nMahathi\r\nMechagāndhāri\r\nSuvarnadeepakam\r\nVijayabhooshāvali',
    compositions: 'composition1,composition2,composition45',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsWehf0fzSJyS7hwLrfUGx6'
  },
  {
    number: '44',
    name: 'Bhavapriya',
    ri: '₁',
    ga: '₂',
    ma: '₂',
    da: '₁',
    ni: '₂',
    chakra: 'Vasu',
    description:
      'Bhavapriya means the one dear to Bhava (Shiva) and is the 44th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Bhavani in Muthuswami Dikshitar school of Carnatic music. It is the 2nd ragam in the 8th chakra Vasu. The mnemonic name is Vasu-Sri. The mnemonic phrase is sa ra gi mi pa dha ni.',
    janyas: 'Bhavāni\r\nKanchanāvathi',
    compositions: 'composition1,composition2,composition46',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXutPKN44yDO3IVvqEiF37KH'
  },
  {
    number: '45',
    name: 'Shubhapantuvarali',
    ri: '₁',
    ga: '₂',
    ma: '₂',
    da: '₁',
    ni: '₃',
    chakra: 'Vasu',
    description:
      'Shubhapantuvarali means the auspicious moon and is the 45th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Shivapantuvaraḻi in Muthuswami Dikshitar school of Carnatic music. Being sad in tone, it is usually used for sad songs by musicians. It is the 3rd ragam in the 8th chakra Vasu. The mnemonic name is Vasu-Go. The mnemonic phrase is sa ra gi mi pa dha nu.\r\n',
    janyas:
      'Shivapanthuvarāli\r\nArunāngi\r\nBandhuvarāli\r\nBhānudhanyāsi\r\nBhānukeeravāni\r\nChāyaranjani\r\nDhowreyani\r\nHindusthāni Todi\r\nJālakesari\r\nKumudhachandrikā\r\nMahānandhini\r\nParpathi\r\nShekharachandrikā',
    compositions: 'composition1,composition2,composition47',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXty3qBt6tEyOt-kjfkd--Jd'
  },
  {
    number: '46',
    name: 'Shadvidamargini',
    ri: '₁',
    ga: '₂',
    ma: '₂',
    da: '₂',
    ni: '₂',
    chakra: 'Vasu',
    description:
      'Shadvidamargini means the one with the road to a hundred forms of knowledge and is the 46th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Stavarajam in Muthuswami Dikshitar school of Carnatic music. It is the 4th ragam in the 8th chakra Vasu. The mnemonic name is Vasu-Bhu. The mnemonic phrase is sa ra gi mi pa dhi ni.',
    janyas:
      'Sthavarājam\r\nGanahemāvati\r\nIndhudhanyāsi\r\nShreekānti\r\nTeevravāhini',
    compositions: 'composition1,composition2,composition48',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXv9JlReMMht7YzEdwadgrNa'
  },
  {
    number: '47',
    name: 'Suvarnangi',
    ri: '₁',
    ga: '₂',
    ma: '₂',
    da: '₂',
    ni: '₃',
    chakra: 'Vasu',
    description:
      'Suvarnangi means the golden-bodied one and is the 47th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Souveeram or Sauviram in Muthuswami Dikshitar school of Carnatic music. It is the 5th ragam in the 8th chakra Vasu. The mnemonic name is Vasu-Ma. The mnemonic phrase is sa ra gi mi pa dhi nu.',
    janyas: 'Sowveeram\r\nAbhiru\r\nRathikā\r\nVijayashree',
    compositions: 'composition1,composition2,composition49',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXv268xScHU0dDg_gSZIaY-L'
  },
  {
    number: '48',
    name: 'Divyamani',
    ri: '₁',
    ga: '₂',
    ma: '₂',
    da: '₃',
    ni: '₃',
    chakra: 'Vasu',
    description:
      'Divyamani means the divine gem and is the 48th melakartha ragam in the 72 melakartha ragam system of Carnatic music.\r\nIt is called Jeevantika or Jeevantini in Muthuswami Dikshitar school of Carnatic music. It is the 6th ragam in the 8th chakra Vasu. The mnemonic name is Vasu-Sha. The mnemonic phrase is sa ra gi mi pa dhu nu.',
    janyas:
      'Jeevanthikā\r\nDeshamukhāri\r\nDundubi\r\nJeevanthini\r\nSuddha Gāndhāri',
    compositions: 'composition1,composition2,composition50',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXvPRUr3o7m5gZucfqvbNjcU'
  },
  {
    number: '49',
    name: 'Dhavalambari',
    ri: '₁',
    ga: '₃',
    ma: '₂',
    da: '₁',
    ni: '₁',
    chakra: 'Brahma',
    description:
      'Dhavalambari is the 49th Melakarta ragam in the 72 melakarta ragam system of Carnatic music. It is called Dhavaḻangam or Dhavaḻangi in Muthuswami Dikshitar school of Carnatic music.\r\nIt is the 1st ragam in the 9th chakra Brahma. The mnemonic name is Brahma-Pa. The mnemonic phrase is sa ra gu mi pa dha na.',
    janyas:
      'Dhavalāngam\r\nAbhirāmam\r\nBhinnapauarali\r\nDharmini\r\nSudharmini',
    compositions: 'composition1,composition2,composition51',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXt-gkqyboEArDLGYfuSyVbX'
  },
  {
    number: '50',
    name: 'Namanarayani',
    ri: '₁',
    ga: '₃',
    ma: '₂',
    da: '₁',
    ni: '₂',
    chakra: 'Brahma',
    description:
      'Namanarayani is the 50th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Namadēshi in Muthuswami Dikshitar school of Carnatic music. It is the 2nd ragam in the 9th chakra Brahma. The mnemonic name is Brahma-Sri. The mnemonic phrase is sa ra gu mi pa dha ni.',
    janyas: 'Nāmadeshi\r\nNarmada\r\nSwaramanjari',
    compositions: 'composition1,composition2,composition52',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXvoq9Ie_ua1mcpgi-hfuCP6'
  },
  {
    number: '51',
    name: 'Kamavardani',
    ri: '₁',
    ga: '₃',
    ma: '₂',
    da: '₁',
    ni: '₃',
    chakra: 'Brahma',
    description:
      'Kamavardhini means that which increases desire and is the 51st Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is also referred by the name Pantuvaraḷi. This ragam is very popular at the beginning of concerts. It is called Kashiramakriya in the Muthuswami Dikshitar school. It is the 3rd ragam in the 9th chakra Brahma. The mnemonic name is Brahma-Go. The mnemonic phrase is sa ra gu mi pa dha nu.',
    janyas:
      'Kāshirāmakriyā\r\nMandāri\r\nādhi Panchama\r\nBasant Bahār\r\nBhogavasantha\r\nDeepakam\r\nGamakapriyā\r\nGamanapriyā\r\nHamsanārāyani\r\nIndumathi\r\nKamalāptapriyā\r\nKumudhakriyā\r\nMāruthi\r\nPonni\r\nPrathāpa\r\nPuriya Dhanashree\r\nTāndavapriyā',
    compositions: 'composition1,composition2,composition53',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXvlgv52YqFALWUaFxBPvdBu'
  },
  {
    number: '52',
    name: 'Ramapriya',
    ri: '₁',
    ga: '₃',
    ma: '₂',
    da: '₂',
    ni: '₂',
    chakra: 'Brahma',
    description:
      'Ramapriya is the 52nd melakarta ragam in the 72 melakarta scale system of Carnatic music.\r\nIt is called Ramamanohari in Muthuswami Dikshitar school of Carnatic music. It is the 4th ragam in the 9th chakra Brahma. The mnemonic name is Brahma-Bhu. The mnemonic phrase is sa ra gu mi pa dhi ni.',
    janyas:
      'Ramāmanohari\r\nChintaramani\r\nHamsagamini\r\nLokaranjani\r\nMeghashyāmala\r\nPatalāmbari\r\nRaktimārgini\r\nRasavinodini\r\nReethi Chandrikā\r\nSeemantinipriyā\r\nSukhakari\r\nVedhaswaroopi',
    compositions: 'composition1,composition2,composition54',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXs_UCpg0meyKvoIqlS7GEfx'
  },
  {
    number: '53',
    name: 'Gamanashrama',
    ri: '₁',
    ga: '₃',
    ma: '₂',
    da: '₂',
    ni: '₃',
    chakra: 'Brahma',
    description:
      'Gamanashrama is the 53rd Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Gamakakriya in Muthuswami Dikshitar school of Carnatic music. It is the 5th ragam in the 9th chakra Brahma. The mnemonic name is Brahma-Ma. The mnemonic phrase is sa ra gu mi pa dhi nu.',
    janyas:
      'Gamakakriyā\r\nAlankāri\r\nBhatiyār\r\nDvigāndhārabhooshani\r\nHamsānandi\r\nMechakāngi\r\nPadmakalyāni\r\nPoorvi Kalyāni\r\nSharabadhvāja\r\nSohini\r\nVaishaka',
    compositions: 'composition1,composition2,composition55',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXvy1ATP3RXKfzRGWGtLn_7N'
  },
  {
    number: '54',
    name: 'Vishwambari',
    ri: '₁',
    ga: '₃',
    ma: '₂',
    da: '₃',
    ni: '₃',
    chakra: 'Brahma',
    description:
      'Vishwambari is the 54th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Vamshavati in Muthuswami Dikshitar school of Carnatic music. It is the 6th ragam in the 9th chakra Brahma. The mnemonic name is Brahma-Sha. The mnemonic phrase is sa ra gu mi pa dhu nu.',
    janyas:
      'Vamshavathi\r\nHemāngi\r\nPooshakalyāni\r\nSharadhyuthi\r\nSuddhakriyā\r\nSundarāngi\r\nVijayavasantham',
    compositions: 'composition1,composition2,composition56',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXul20Uw9BrYjhZciVCcOXLs'
  },
  {
    number: '55',
    name: 'Shamalangi',
    ri: '₂',
    ga: '₂',
    ma: '₂',
    da: '₁',
    ni: '₁',
    chakra: 'Disi',
    description:
      'Shamalangi is the 55th in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Shamaḻam in the Muthuswami Dikshitar school of that genre. Shamalang is the 1st ragam in the 10th chakra Disi. Its mnemonic name is Disi-Pa; the mnemonic phrase associated with it is sa ri gi mi pa dha na.',
    janyas: 'Shyāmalam\r\nDeshāvali\r\nVijayamālavi',
    compositions: 'composition1,composition2,composition57',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXtg64DwZyAOqu0gRUm_bSAx'
  },
  {
    number: '56',
    name: 'Shanmukhapriya',
    ri: '₂',
    ga: '₂',
    ma: '₂',
    da: '₁',
    ni: '₂',
    chakra: 'Disi',
    description:
      'Shanmukhapriya is the 56th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Chamaram in Muthuswami Dikshitar school of Carnatic music. Many compositions on Lord Murugan and Lord Shiva are based on this raga. It is the 2nd ragam in the 10th chakra Disi. The mnemonic name is Disi-Sri. The mnemonic phrase is sa ri gi mi pa dha ni.',
    janyas:
      'Chāmaram\r\nBhāshini\r\nChintāmani\r\nDhanakari\r\nGarigadya\r\nGopikathilakam\r\nKokilanandhi\r\nRājeshwari\r\nSamudrapriyā\r\nShanmukhi (Trimoorti)\r\nSumanasaranjani\r\nVasukari',
    compositions: 'composition1,composition2,composition58',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXujGG-PHIoH0x_OKvC8llAF'
  },
  {
    number: '57',
    name: 'Simhendramadhyamam',
    ri: '₂',
    ga: '₂',
    ma: '₂',
    da: '₁',
    ni: '₃',
    chakra: 'Disi',
    description:
      'Simhendramadhyamam is the 57th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Sumadyuti in Muthuswami Dikshitar school of Carnatic music. It is the 3rd ragam in the 10th chakra Disi. The mnemonic name is Disi-Go. The mnemonic phrase is sa ri gi mi pa dha ni.',
    janyas:
      'Ānandavalli\r\nGhantana\r\nJayachoodāmani\r\nPranavapriyā\r\nSarvāngi\r\nSeshanādam\r\nSuddha\r\nSunādapriyā\r\nUrmikā\r\nVijayasaraswathi',
    compositions: 'composition1,composition2,composition59',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXvjQ5dvYhkvTV9ueRW59Rf4'
  },
  {
    number: '58',
    name: 'Hemavati',
    ri: '₂',
    ga: '₂',
    ma: '₂',
    da: '₂',
    ni: '₂',
    chakra: 'Disi',
    description:
      'Hemavati is the 58th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Simharavam or Deshi Simharavam in Muthuswami Dikshitar school of Carnatic music. Nadaswaram players are especially fond of the ragam. It is the 4th ragam in the 10th chakra Disi. The mnemonic name is Disi-Bhu. The mnemonic phrase is sa ri gi mi pa dhi ni.',
    janyas:
      'Deshisimhāravam\r\nChandrarekhā\r\nHamsabhramari\r\nHemāmbari\r\nHemapriya\r\nKshemakari\r\nMadhukowns\r\nShakthiroopini\r\nSimhārava\r\nVijayasāranga\r\nVijayashrāngi\r\nYāgini',
    compositions: 'composition1,composition2,composition60',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsvS7adViuydD0aRo0cgEXM'
  },
  {
    number: '59',
    name: 'Dharmavati',
    ri: '₂',
    ga: '₂',
    ma: '₂',
    da: '₂',
    ni: '₃',
    chakra: 'Disi',
    description:
      'Dharmavati is the 59th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Dhaamavati in Muthuswami Dikshitar school of Carnatic music. It is the 5th ragam in the 10th chakra Disi. The mnemonic name is Disi-Ma. The mnemonic phrase is sa ri gi mi pa dhi nu.',
    janyas:
      'Dhāmavathi\r\nGowrikriya\r\nKarmukhāvati\r\nKarpoora Bharani\r\nLalitasimharavam\r\nMadhumālathi\r\nMadhuvanthi\r\nMoharanjani\r\nRanjani\r\nVarada\r\nVijayanāgari\r\nVishveshwarapriyā',
    compositions: 'composition1,composition2,composition61',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsBFpabEJe40OMKak1HBH4R'
  },
  {
    number: '60',
    name: 'Neetimati',
    ri: '₂',
    ga: '₂',
    ma: '₂',
    da: '₃',
    ni: '₃',
    chakra: 'Disi',
    description:
      'Neetimati is the 60th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Nishadham in Muthuswami Dikshitar school of Carnatic music. It is the 6th ragam in the 10th chakra Disi. The mnemonic name is Disi-Sha. The mnemonic phrase is sa ri gi mi pa dhu nu.',
    janyas:
      'Nisshadham\r\nAmarasenapriyā\r\nDeshyagānavaridhi\r\nHamsanādam\r\nKaikavashi\r\nNuthanachandrikā\r\nRathnasāranga',
    compositions: 'composition1,composition2,composition62',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXuHKxo9I6gU9Or09G-ofqqj'
  },
  {
    number: '61',
    name: 'Kantamani',
    ri: '₂',
    ga: '₃',
    ma: '₂',
    da: '₁',
    ni: '₁',
    chakra: 'Rudra',
    description:
      'Kantamani is the 61st Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Kuntalam in Muthuswami Dikshitar school of Carnatic music. It is the 1st ragam in the 11th chakra Rudra. The mnemonic name is Rudra-Pa. The mnemonic phrase is sa ri gu mi pa dha na.',
    janyas: 'Kunthalam\r\nKanakakusumāvali\r\nShruthiranjani',
    compositions: 'composition1,composition2,composition63',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsMCQ0BLr4IUZN0jrEJPhKP'
  },
  {
    number: '62',
    name: 'Rishabhapriya',
    ri: '₂',
    ga: '₃',
    ma: '₂',
    da: '₁',
    ni: '₂',
    chakra: 'Rudra',
    description:
      'Rishabhapriya is the 62nd melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Ratipriya in Muthuswami Dikshitar school of Carnatic music. It is the 2nd ragam in the 11th chakra Rudra. The mnemonic name is Rudra-Sri. The mnemonic phrase is sa ri gu mi pa dha ni.',
    janyas:
      'Rathipriyā\r\nGopriya\r\nPoornasāveri\r\nRathnabhānu\r\nSuddha Sāranga',
    compositions: 'composition1,composition2,composition64',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsrjKdi69ELPgxWpW9LY0L9'
  },
  {
    number: '63',
    name: 'Latangi',
    ri: '₂',
    ga: '₃',
    ma: '₂',
    da: '₁',
    ni: '₃',
    chakra: 'Rudra',
    description:
      'Latangi is the 63rd Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Geetapriya in Muthuswami Dikshitar school of Carnatic music. It is the 3rd ragam in the 11th chakra Rudra. The mnemonic name is Rudra-Go. The mnemonic phrase is sa ri gu mi pa dha nu.',
    janyas:
      'Geethapriyā\r\nChitrachandrika\r\nHamsalatha\r\nKananapriyā\r\nKarunākari\r\nLalithāngi\r\nRamani\r\nRathnakānthi\r\nRaviswaroopini\r\nSajjananandhi\r\nSkandamanorama',
    compositions: 'composition1,composition2,composition65',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXs5btAjLvarymFL1ncz8MR9'
  },
  {
    number: '64',
    name: 'Vachaspati',
    ri: '₂',
    ga: '₃',
    ma: '₂',
    da: '₂',
    ni: '₂',
    chakra: 'Rudra',
    description:
      'Vachaspati means lord of speech and is the 64th melakarta ragam in the 72 melakarta ragam system.\r\nIt is known as Bhushavati according to the Muthuswami Dikshitar school. It is the 4th ragam in the 11th chakra Rudra. The mnemonic name is Rudra-Bhu. The mnemonic phrase is sa ri gu mi pa dhi ni.',
    janyas:
      'Bhooshāvathi\r\nBhagavataranjana\r\nBhogeeshwari\r\nBhooshāvali\r\nDwigāndhārabhooshani\r\nGaganamohini\r\nGurupriya\r\nHrdhini\r\nMangalakari\r\nMukthidāyini\r\nNādhabrahma\r\nPranavākāri\r\nSaraswathi\r\nTriveni\r\nUtthari\r\nVivāhapriyā',
    compositions: 'composition1,composition2,composition66',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXvhfHoRY3beQbkSR47D7N96'
  },
  {
    number: '65',
    name: 'Mechakalyani',
    ri: '₂',
    ga: '₃',
    ma: '₂',
    da: '₂',
    ni: '₃',
    chakra: 'Rudra',
    description:
      'Mechakalyani means she who causes auspicious things and is the 65th melakarta raga in the 72 melakartha ragam system.\r\nIt is the 5th ragam in the 11th chakra Rudra. The mnemonic name is Rudra-Ma. The mnemonic phrase is sa ri gu mi pa dhi nu. This raga is very significant to the chart, because it is sung with all higher swaras. It is also called Kalyani and is the first Prathi Madhyama raga that was ever discovered. The western equivalent is the Lydian mode. It is a very prominently played raga at South Indian weddings.\r\nKalyani has scope for elaborate alapana. One should not remain too long on panchamam (pa) or alternate between shadjamam and panchamam too frequently. Kalyani is prominently known among the public. It is often performed at the beginning of concerts because it is considered auspicious.\r\nKalyani is also considered one of the major ragams of Carnatic music along with Sankarabharanam, Todi and Kharaharapriya. This set of major ragams is an informal grouping of the most popular ragams used for elaboration and exploration, and which often form the centerpiece of a Carnatic music concert in the form of a Ragam Tanam Pallavi or Kriti. ',
    janyas:
      'Shānthakalyāni\r\nAmritha Behāg\r\nAprameya\r\nBhoopkalyāni\r\nChandrakāntha\r\nHameer (Kalyan, Yaman Thāt Janya)\r\nHameer Kalyāni\r\nHamsakalyāni\r\nKalyānadāyini\r\nKannadamaruva\r\nKowmoda\r\nKunthalashreekānti\r\nMohana Kalyāni\r\nMrgānandhana\r\nNādhakalyāni\r\nPramodhini\r\nSāranga\r\nSāranga Tārangini\r\nShilangi\r\nSuddha Koshala\r\nSunādavinodini\r\nSwayambhooshwara Rāga\r\nVandanadhārini\r\nYamuna Kalyāni',
    compositions: 'composition1,composition2,composition67',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXvI-k-D_0nmteaPYWFVG1XD'
  },
  {
    number: '66',
    name: 'Chitrambari',
    ri: '₂',
    ga: '₃',
    ma: '₂',
    da: '₃',
    ni: '₃',
    chakra: 'Rudra',
    description:
      'Chitrambari is the 66th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Chaturangini in Muthuswami Dikshitar school of Carnatic music. It is the 6th ragam in the 11th chakra Rudra. The mnemonic name for this ragam is Rudra-Sha. The mnemonic phrase is sa ra gu mi pa dhu nu.',
    janyas:
      'Chaturāngini\r\nAmritavarshini\r\nChitrasindhu\r\nChurnikavinodhini\r\nVijayakoshalam',
    compositions: 'composition1,composition2,composition68',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXtHp8Sd7h-RSZTsDZOUmXfw'
  },
  {
    number: '67',
    name: 'Sucharitra',
    ri: '₃',
    ga: '₃',
    ma: '₂',
    da: '₁',
    ni: '₁',
    chakra: 'Aditya',
    description:
      'Sucharitra is the 67th melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Santana manjari in Muthuswami Dikshitar school of Carnatic music. It is the 1st ragam in the 12th chakra Aditya. The mnemonic name is Aditya-Pa. The mnemonic phrase is sa ru gu mi pa dha na.',
    janyas: 'Santhāna Manjari',
    compositions: 'composition1,composition2,composition69',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXvJ_fwhKjIiXg6P5Bium19Q'
  },
  {
    number: '68',
    name: 'Jyoti Swarupini',
    ri: '₃',
    ga: '₃',
    ma: '₂',
    da: '₁',
    ni: '₂',
    chakra: 'Aditya',
    description:
      'Jyoti Swarupini is the 68th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Jyotiraga or Jyoti in Muthuswami Dikshitar school of Carnatic music. It is the 2nd ragam in the 12th chakra Aditya. The mnemonic name for this ragam is Aditya-Sri. The mnemonic phrase is sa ru gu mi pa dha ni.',
    janyas: 'Jyothi\r\nDeepavarāli\r\nJyothishmathi\r\nRāmagiri',
    compositions: 'composition1,composition2,composition70',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXvgg9NoB6NzHOS8zqWb_UId'
  },
  {
    number: '69',
    name: 'Dhatuvardani',
    ri: '₃',
    ga: '₃',
    ma: '₂',
    da: '₁',
    ni: '₃',
    chakra: 'Aditya',
    description:
      'Dhatuvardhani is the 69th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Dhautapanchamam in Muthuswami Dikshitar school of Carnatic music. Dhatuvardhani is the 3rd ragam in the 12th chakra Aditya. The mnemonic name is Aditya-Go. The mnemonic phrase is sa ru gu mi pa dha nu.',
    janyas: 'Dhowtha Panchamam\r\nDhwithiyapanchamam\r\nSumukham\r\nTavapriyā',
    compositions: 'composition1,composition2,composition71',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXsuowgcZvJlYC9uTvoPbN9v'
  },
  {
    number: '70',
    name: 'Nasikabhushani',
    ri: '₃',
    ga: '₃',
    ma: '₂',
    da: '₂',
    ni: '₂',
    chakra: 'Aditya',
    description:
      'Nasikabhushani is the 70th Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Nasamaṇi in Muthuswami Dikshitar school of Carnatic music. Nasikabhushani is the 4th ragam in the 12th chakra Aditya. The mnemonic name is Aditya-Bhu. The mnemonic phrase is sa ru gu mi pa dhi ni.',
    janyas: 'Nāsāmani\r\nMarakathagowla\r\nThilakamandāri',
    compositions: 'composition1,composition2,composition72',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXv-7kawmvg_f_O1OTJdfBRq'
  },
  {
    number: '71',
    name: 'Kosalam',
    ri: '₃',
    ga: '₃',
    ma: '₂',
    da: '₂',
    ni: '₃',
    chakra: 'Aditya',
    description:
      'Kosalam is the 71st Melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Kusumakaram in Muthuswami Dikshitar school of Carnatic music. Kosalam is the 5th ragam in the 12th chakra Aditya. The mnemonic name is Aditya-Ma. The mnemonic phrase is sa ru gu mi pa dhi nu.',
    janyas: 'Kusumākaram\r\nAyodhya',
    compositions: 'composition1,composition2,composition73',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXuZc6vkVlOXcYX1NpACI-qJ'
  },
  {
    number: '72',
    name: 'Rasikapriya',
    ri: '₃',
    ga: '₃',
    ma: '₂',
    da: '₃',
    ni: '₃',
    chakra: 'Aditya',
    description:
      'Rasikapriya is the final, 72nd melakarta ragam in the 72 melakarta ragam system of Carnatic music.\r\nIt is called Rasamanjari in Muthuswami Dikshitar school of Carnatic music. It is the 6th ragam in the 12th chakra Aditya. The mnemonic name is Aditya-Sha. The mnemonic phrase is sa ru gu mi pa dhu nu.',
    janyas: 'Rasamanjari\r\nHamsagiri\r\nIshtārangini\r\nNāgagiri',
    compositions: 'composition1,composition2,composition74',
    playlist:
      'https://www.youtube.com/playlist?list=PLhXXXBnUmJXvWi-v2L3d6dDpujGXL7llf'
  }
]
