export const talamFamilies = [
  {
    name: 'Adi',
    anga: 'IOO',
    defaultJati: 'Chatusra'
  },
  {
    name: 'Dhruva',
    anga: 'IOII',
    defaultJati: 'Chatusra'
  },
  {
    name: 'Matya',
    anga: 'IOI',
    defaultJati: 'Chatusra'
  },
  {
    name: 'Rupaka',
    anga: 'OI',
    defaultJati: 'Chatusra'
  },
  {
    name: 'Jhampa',
    anga: 'IUO',
    defaultJati: 'Misra'
  },
  {
    name: 'Triputa',
    anga: 'IOO',
    defaultJati: 'Tisra'
  },
  {
    name: 'Ata',
    anga: 'IIOO',
    defaultJati: 'Khanda'
  },
  {
    name: 'Eka',
    anga: 'I',
    defaultJati: 'Chatusra'
  }
]
