export const beginnerLessonData = [
  // all ragam = Mayamalavagowla
  // all talam = Adi Talam
  {
    number: 1,
    varasai: 'Sarali',
    notes: [
      'S R G M ǀ P D ǀ N Ṡ ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ',
      'S R-S R-ǀ S R ǀ G M ǁ\r\nS R G M ǀ P D ǀ N Ṡ ǁ\r\nṠ N-Ṡ N-ǀ Ṡ N ǀ D P ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ',
      'S R G-S ǀ R G-ǀ S R ǁ\r\nS R G M ǀ P D ǀ N Ṡ ǁ\r\nṠ N D-S ǀ N D-ǀ S N ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ',
      'S R G M-ǀ S R ǀ G M-ǁ\r\nS R G M ǀ P D ǀ N S ǁ\r\nṠ N D P-ǀ Ṡ N ǀ D P-ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ',
      'S R G M ǀ P ,-ǀ S R ǁ\r\nS R G M ǀ P D ǀ N Ṡ ǁ\r\nṠ N D P ǀ M ,-ǀ Ṡ N ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ',
      'S R G M ǀ P D-ǀ S R ǁ\r\nS R G M ǀ P D ǀ N Ṡ ǁ\r\nṠ N D P ǀ M G-ǀ Ṡ N ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ',
      'S R G M ǀ P D ǀ N , ǁ\r\nS R G M ǀ P D ǀ N Ṡ ǁ\r\nṠ N D P ǀ M G ǀ R , ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ',
      'S R G M ǀ P M ǀ G R ǁ\r\nS R G M ǀ P D ǀ N Ṡ ǁ\r\nṠ N D P ǀ M P ǀ D N ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ',
      'S R G M ǀ P M ǀ D P ǁ\r\nS R G M ǀ P D ǀ N Ṡ ǁ\r\nṠ N D P ǀ M P ǀ G M ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ',
      'S R G M ǀ P , ǀ G M ǁ\r\nP , , , ǀ P , ǀ , , ǁ\r\nG M P D ǀ N D ǀ P M ǁ\r\nG M P-G ǀ M G ǀ R S ǁ',
      'Ṡ , N D ǀ N , ǀ D P ǁ\r\nD , P M ǀ P , ǀ P , ǁ\r\nG M P D ǀ N D ǀ P M ǁ\r\nG M P-G ǀ M G ǀ R S ǁ',
      'Ṡ Ṡ N D ǀ N N ǀ D P ǁ\r\nD D P M ǀ P , ǀ P , ǁ\r\nG M P D ǀ N D ǀ P M ǁ\r\nG M P-G ǀ M G ǀ R S ǁ',
      'S R G R ǀ G ,-ǀ G M ǁ\r\nP M P ,-ǀ D P ǀ D , ǁ\r\nM P D P ǀ D N ǀ D P ǁ\r\nM P D P ǀ M G ǀ R S ǁ',
      'S R G M ǀ P , ǀ P , ǁ\r\nD D P , ǀ M M ǀ P , ǁ\r\nD N Ṡ , ǀ Ṡ N ǀ D P ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ'
    ]
  },
  {
    number: 2,
    varasai: 'Jantai',
    notes: [
      'S S R R ǀ G G ǀ M M ǁ\r\nP P D D ǀ N N ǀ Ṡ Ṡ ǁ',
      'S S R R ǀ G G ǀ M M ǁ\r\nR R G G ǀ M M ǀ P P ǁ\r\nG G M M ǀ P P ǀ D D ǁ\r\nM M P P ǀ D D ǀ N N ǁ\r\nP P D D ǀ N N ǀ Ṡ Ṡ ǁ\r\nṠ Ṡ N N ǀ D D ǀ P P ǁ\r\nN N D D ǀ P P ǀ M M ǁ\r\nD D P P ǀ M M ǀ G G ǁ\r\nP P M M ǀ G G ǀ R R ǁ\r\nM M G G ǀ R R ǀ S S ǁ',
      'S S R R ǀ G G ǀ R R ǁ\r\nS S R R ǀ G G ǀ M M ǁ\r\nR R G G ǀ M M ǀ G G ǁ\r\nR R G G ǀ M M ǀ P P ǁ\r\nG G M M ǀ P P ǀ M M ǁ\r\nG G M M ǀ P P ǀ D D ǁ\r\nM M P P ǀ D D ǀ P P ǁ\r\nM M P P ǀ D D ǀ N N ǁ\r\nP P D D ǀ N N ǀ D D ǁ\r\nP P D D ǀ N N ǀ Ṡ Ṡ ǁ\r\nṠ Ṡ N N ǀ D D ǀ N N ǁ\r\nṠ Ṡ N N ǀ D D ǀ P P ǁ\r\nN N D D ǀ P P ǀ D D ǁ\r\nN N D D ǀ P P ǀ M M ǁ\r\nD D P P ǀ M M ǀ P P ǁ\r\nD D P P ǀ M M ǀ G G ǁ\r\nP P M M ǀ G G ǀ M M ǁ\r\nP P M M ǀ G G ǀ R R ǁ\r\nM M G G ǀ R R ǀ G G ǁ\r\nM M G G ǀ R R ǀ S S ǁ',
      'S S R-S ǀ S R ǀ S R ǁ\r\nS S R R ǀ G G ǀ M M ǁ\r\nR R G-R ǀ R G ǀ R G ǁ\r\nR R G G ǀ M M ǀ P P ǁ\r\nG G M-G ǀ G M ǀ G M ǁ\r\nG G M M ǀ P P ǀ D D ǁ\r\nM M P-M ǀ M P ǀ M P ǁ\r\nM M P P ǀ D D ǀ N N ǁ\r\nP P D-P ǀ P D ǀ P D ǁ\r\nP P D D ǀ N N ǀ Ṡ Ṡ ǁ\r\nṠ Ṡ N-Ṡ ǀ Ṡ N ǀ Ṡ N ǁ\r\nṠ Ṡ N N ǀ D D ǀ P P ǁ\r\nN N D-N ǀ N D ǀ N D ǁ\r\nN N D D ǀ P P ǀ M M ǁ\r\nD D P-D ǀ D P ǀ D P ǁ\r\nD D P P ǀ M M ǀ G G ǁ\r\nP P M-P ǀ P M ǀ P M ǁ\r\nP P M M ǀ G G ǀ R R ǁ\r\nM M G-M ǀ M G ǀ M G ǁ\r\nM M G G ǀ R R ǀ S S ǁ',
      'S S R R ǀ G-S ǀ R G ǁ\r\nS S R R ǀ G G ǀ M M ǁ\r\nR R G G ǀ M-R ǀ G M ǁ\r\nR R G G ǀ M M ǀ P P ǁ\r\nG G M M ǀ P-G ǀ M P ǁ\r\nG G M M ǀ P P ǀ D D ǁ\r\nM M P P ǀ D-M ǀ P D ǁ\r\nM M P P ǀ D D ǀ N N ǁ\r\nP P D D ǀ N-P ǀ D N ǁ\r\nP P D D ǀ N N ǀ Ṡ Ṡ ǁ\r\nṠ Ṡ N N ǀ D-Ṡ ǀ N D ǁ\r\nṠ Ṡ N N ǀ D D ǀ P P ǁ\r\nN N D D ǀ P-N ǀ D P ǁ\r\nN N D D ǀ P P ǀ M M ǁ\r\nD D P P ǀ M-D ǀ P M ǁ\r\nD D P P ǀ M M ǀ G G ǁ\r\nP P M M ǀ G-P ǀ M G ǁ\r\nP P M M ǀ G G ǀ R R ǁ\r\nM M G G ǀ R-M ǀ G R ǁ\r\nM M G G ǀ R R ǀ S S ǁ',
      'S S , R ǀ R , ǀ G G ǁ\r\nS S R R ǀ G G ǀ M M ǁ\r\nR R , G ǀ G , ǀ M M ǁ\r\nR R G G ǀ M M ǀ P P ǁ\r\nG G , M ǀ M , ǀ P P ǁ\r\nG G M M ǀ P P ǀ D D ǁ\r\nM M , P ǀ P , ǀ D D ǁ\r\nM M P P ǀ D D ǀ N N ǁ\r\nP P , D ǀ D , ǀ N N ǁ\r\nP P D D ǀ N N ǀ Ṡ Ṡ ǁ\r\nṠ Ṡ , N ǀ N , ǀ D D ǁ\r\nṠ Ṡ N N ǀ D D ǀ P P ǁ\r\nN N , D ǀ D , ǀ P P ǁ\r\nN N D D ǀ P P ǀ M M ǁ\r\nD D , P ǀ P , ǀ M M ǁ\r\nD D P P ǀ M M ǀ G G ǁ\r\nP P , M ǀ M , ǀ G G ǁ\r\nP P M M ǀ G G ǀ R R ǁ\r\nM M , G ǀ G , ǀ R R ǁ\r\nM M G G ǀ R R ǀ S S ǁ',
      'S , S R ǀ , R ǀ G G ǁ\r\nS S R R ǀ G G ǀ M M ǁ\r\nR , R G ǀ , G ǀ M M ǁ\r\nR R G G ǀ M M ǀ P P ǁ\r\nG , G M ǀ , M ǀ P P ǁ\r\nG G M M ǀ P P ǀ D D ǁ\r\nM , M P ǀ , P ǀ D D ǁ\r\nM M P P ǀ D D ǀ N N ǁ\r\nP , P D ǀ , D ǀ N N ǁ\r\nP P D D ǀ N N ǀ Ṡ Ṡ ǁ\r\nṠ , Ṡ N ǀ , N ǀ D D ǁ\r\nṠ Ṡ N N ǀ D D ǀ P P ǁ\r\nN , N D ǀ , D ǀ P P ǁ\r\nN N D D ǀ P P ǀ M M ǁ\r\nD , D P ǀ , P ǀ M M ǁ\r\nD D P P ǀ M M ǀ G G ǁ\r\nP , P M ǀ , M ǀ G G ǁ\r\nP P M M ǀ G G ǀ R R ǁ\r\nM , M G ǀ , G ǀ R R ǁ\r\nM M G G ǀ R R ǀ S S ǁ',
      'S S S R ǀ R R ǀ G G ǁ\r\nS S R R ǀ G G ǀ M M ǁ\r\nR R R G ǀ G G ǀ M M ǁ\r\nR R G G ǀ M M ǀ P P ǁ\r\nG G G M ǀ M M ǀ P P ǁ\r\nG G M M ǀ P P ǀ D D ǁ\r\nM M M P ǀ P P ǀ D D ǁ\r\nM M P P ǀ D D ǀ N N ǁ\r\nP P P D ǀ D D ǀ N N ǁ\r\nP P D D ǀ N N ǀ Ṡ Ṡ ǁ\r\nṠ Ṡ Ṡ N ǀ N N ǀ D D ǁ\r\nṠ Ṡ N N ǀ D D ǀ P P ǁ\r\nN N N D ǀ D D ǀ P P ǁ\r\nN N D D ǀ P P ǀ M M ǁ\r\nD D D P ǀ P P ǀ M M ǁ\r\nD D P P ǀ M M ǀ G G ǁ\r\nP P P M ǀ M M ǀ G G ǁ\r\nP P M M ǀ G G ǀ R R ǁ\r\nM M M G ǀ G G ǀ R R ǁ\r\nM M G G ǀ R R ǀ S S ǁ',
      'S , R G ǀ ,-S ǀ R G ǁ\r\nS S R R ǀ G G ǀ M M ǁ\r\nR , G M ǀ ,-R ǀ G M ǁ\r\nR R G G ǀ M M ǀ P P ǁ\r\nG , M P ǀ ,-G ǀ M P ǁ\r\nG G M M ǀ P P ǀ D D ǁ\r\nM , P D ǀ ,-M ǀ P D ǁ\r\nM M P P ǀ D D ǀ N N ǁ\r\nP , D N ǀ ,-P ǀ D N ǁ\r\nP P D D ǀ N N ǀ Ṡ Ṡ ǁ\r\nṠ , N D ǀ ,-Ṡ ǀ N D ǁ\r\nṠ Ṡ N N ǀ D D ǀ P P ǁ\r\nN , D P ǀ ,-N ǀ D P ǁ\r\nN N D D ǀ P P ǀ M M ǁ\r\nD , P M ǀ ,-D ǀ P M ǁ\r\nD D P P ǀ M M ǀ G G ǁ\r\nP , M G ǀ ,-P ǀ M G ǁ\r\nP P M M ǀ G G ǀ R R ǁ\r\nM , G R ǀ ,-M ǀ G R ǁ\r\nM M G G ǀ R R ǀ S S ǁ',
      'S R , G ǀ ,-S ǀ R G ǁ\r\nS S R R ǀ G G ǀ M M ǁ\r\nR G , M ǀ ,-R ǀ G M ǁ\r\nR R G G ǀ M M ǀ P P ǁ\r\nG M , P ǀ ,-G ǀ M P ǁ\r\nG G M M ǀ P P ǀ D D ǁ\r\nM P , D ǀ ,-M ǀ P D ǁ\r\nM M P P ǀ D D ǀ N N ǁ\r\nP D , N ǀ ,-P ǀ D N ǁ\r\nP P D D ǀ N N ǀ Ṡ Ṡ ǁ\r\nṠ N , D ǀ ,-Ṡ ǀ N D ǁ\r\nṠ Ṡ N N ǀ D D ǀ P P ǁ\r\nN D , P ǀ ,-N ǀ D P ǁ\r\nN N D D ǀ P P ǀ M M ǁ\r\nD P , M ǀ ,-D ǀ P M ǁ\r\nD D P P ǀ M M ǀ G G ǁ\r\nP M , G ǀ ,-P ǀ M G ǁ\r\nP P M M ǀ G G ǀ R R ǁ\r\nM G , R ǀ ,-M ǀ G R ǁ\r\nM M G G ǀ R R ǀ S S ǁ',
      'S , R , ǀ G-S ǀ R G ǁ\r\nS S R R ǀ G G ǀ M M ǁ\r\nR , G , ǀ M-R ǀ G M ǁ\r\nR R G G ǀ M M ǀ P P ǁ\r\nG , M , ǀ P-G ǀ M P ǁ\r\nG G M M ǀ P P ǀ D D ǁ\r\nM , P , ǀ D-M ǀ P D ǁ\r\nM M P P ǀ D D ǀ N N ǁ\r\nP , D , ǀ N-P ǀ D N ǁ\r\nP P D D ǀ N N ǀ Ṡ Ṡ ǁ\r\nṠ , N , ǀ D-Ṡ ǀ N D ǁ\r\nṠ Ṡ N N ǀ D D ǀ P P ǁ\r\nN , D , ǀ P-N ǀ D P ǁ\r\nN N D D ǀ P P ǀ M M ǁ\r\nD , P , ǀ M-D ǀ P M ǁ\r\nD D P P ǀ M M ǀ G G ǁ\r\nP , M , ǀ G-P ǀ M G ǁ\r\nP P M M ǀ G G ǀ R R ǁ\r\nM , G , ǀ R-M ǀ G R ǁ\r\nM M G G ǀ R R ǀ S S ǁ',
      'S S M M ǀ G G ǀ R R ǁ\r\nS S R R ǀ G G ǀ M M ǁ\r\nR R P P ǀ M M ǀ G G ǁ\r\nR R G G ǀ M M ǀ P P ǁ\r\nG G D D ǀ P P ǀ M M ǁ\r\nG G M M ǀ P P ǀ D D ǁ\r\nM M N N ǀ D D ǀ P P ǁ\r\nM M P P ǀ D D ǀ N N ǁ\r\nP P Ṡ Ṡ ǀ N N ǀ D D ǁ\r\nP P D D ǀ N N ǀ Ṡ Ṡ ǁ\r\nṠ Ṡ P P ǀ D D ǀ N N ǁ\r\nṠ Ṡ N N ǀ D D ǀ P P ǁ\r\nN N M M ǀ P P ǀ D D ǁ\r\nN N D D ǀ P P ǀ M M ǁ\r\nD D G G ǀ M M ǀ P P ǁ\r\nD D P P ǀ M M ǀ G G ǁ\r\nP P R R ǀ G G ǀ M M ǁ\r\nP P M M ǀ G G ǀ R R ǁ\r\nM M S S ǀ R R ǀ G G ǁ\r\nM M G G ǀ R R ǀ S S ǁ'
    ]
  },
  {
    number: 3,
    varasai: 'Dattu',
    notes: [
      'S S M M ǀ R R ǀ G G ǁ\r\nS S R R ǀ G G ǀ M M ǁ\r\nR R P P ǀ G G ǀ M M ǁ\r\nR R G G ǀ M M ǀ P P ǁ\r\nG G D D ǀ M M ǀ P P ǁ\r\nG G M M ǀ P P ǀ D D ǁ\r\nM M N N ǀ P P ǀ D D ǁ\r\nM M P P ǀ D D ǀ N N ǁ\r\nP P Ṡ Ṡ ǀ D D ǀ N N ǁ\r\nP P D D ǀ N N ǀ Ṡ Ṡ ǁ\r\nṠ Ṡ P P ǀ N N ǀ D D ǁ\r\nṠ Ṡ N N ǀ D D ǀ P P ǁ\r\nN N M M ǀ D D ǀ P P ǁ\r\nN N D D ǀ P P ǀ M M ǁ\r\nD D G G ǀ P P ǀ M M ǁ\r\nD D P P ǀ M M ǀ G G ǁ\r\nP P R R ǀ M M ǀ G G ǁ\r\nP P M M ǀ G G ǀ R R ǁ\r\nM M S S ǀ G G ǀ R R ǁ\r\nM M G G ǀ R R ǀ S S ǁ',
      'S M G M ǀ R G ǀ S R ǁ\r\nS G R G ǀ S R ǀ G M ǁ\r\nR P M P ǀ G M ǀ R G ǁ\r\nR M G M ǀ R G ǀ M P ǁ\r\nG D P D ǀ M P ǀ G M ǁ\r\nG P M P ǀ G M ǀ P D ǁ\r\nM N D N ǀ P D ǀ M P ǁ\r\nM D P D ǀ M P ǀ D N ǁ\r\nP Ṡ N Ṡ ǀ D N ǀ P D ǁ\r\nP N D N ǀ P D ǀ N Ṡ ǁ\r\nṠ P D P ǀ N D ǀ Ṡ N ǁ\r\nṠ D N D ǀ Ṡ N ǀ D P ǁ\r\nN M P M ǀ D P ǀ N D ǁ\r\nN P D P ǀ N D ǀ P M ǁ\r\nD G M G ǀ P M ǀ D P ǁ\r\nD M P M ǀ D P ǀ M G ǁ\r\nP R G R ǀ M G ǀ P M ǁ\r\nP G M G ǀ P M ǀ G R ǁ\r\nM S R S ǀ G R ǀ M G ǁ\r\nM R G R ǀ M G ǀ R S ǁ',
      'S R S G ǀ R G ǀ R M ǁ\r\nS M G R ǀ S R ǀ G M ǁ\r\nR G R M ǀ G M ǀ G P ǁ\r\nR P M G ǀ R G ǀ M P ǁ\r\nG M G P ǀ M P ǀ M D ǁ\r\nG D P M ǀ G M ǀ P D ǁ\r\nM P M D ǀ P D ǀ P N ǁ\r\nM N D P ǀ M P ǀ D N ǁ\r\nP D P N ǀ D N ǀ D Ṡ ǁ\r\nP Ṡ N D ǀ P D ǀ N Ṡ ǁ\r\nṠ N Ṡ D ǀ N D ǀ N P ǁ\r\nṠ P D N ǀ Ṡ N ǀ D P ǁ\r\nN D N P ǀ D P ǀ D M ǁ\r\nN M P D ǀ N D ǀ P M ǁ\r\nD P D M ǀ P M ǀ P G ǁ\r\nD G M P ǀ D P ǀ M G ǁ\r\nP M P G ǀ M G ǀ M R ǁ\r\nP R G M ǀ P M ǀ G R ǁ\r\nM G M R ǀ G R ǀ G S ǁ\r\nM S R G ǀ M G ǀ R S ǁ'
    ]
  },
  {
    number: 4,
    varasai: 'Melstaayi',
    notes: [
      'S R G M ǀ P D ǀ N Ṡ ǁ\r\nṠ , , , ǀ Ṡ , ǀ , , ǁ\r\nD N Ṡ R ǀ Ṡ N ǀ D P ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ',
      'S R G M ǀ P D ǀ N Ṡ ǁ\r\nṠ , , , ǀ Ṡ , ǀ , , ǁ\r\nD N Ṡ R ǀ Ṡ Ṡ ǀ R Ṡ ǁ\r\nṠ R-Ṡ N ǀ D P ǀ M P ǁ\r\nD N Ṡ R ǀ Ṡ N ǀ D P ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ',
      'S R G M ǀ P D ǀ N Ṡ ǁ\r\nṠ , , , ǀ Ṡ , ǀ , , ǁ\r\nD N Ṡ R ǀ G R ǀ Ṡ R ǁ\r\nṠ R-Ṡ N ǀ D P ǀ M P ǁ\r\nD N Ṡ R ǀ Ṡ Ṡ ǀ R Ṡ ǁ\r\nṠ R-Ṡ N ǀ D P ǀ M P ǁ\r\nD N Ṡ R ǀ Ṡ N ǀ D P ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ',
      'S R G M ǀ P D ǀ N Ṡ ǁ\r\nṠ , , , ǀ Ṡ , ǀ , , ǁ\r\nD N Ṡ R ǀ G M ǀ G R ǁ\r\nṠ R-Ṡ N ǀ D P ǀ M P ǁ\r\nD N Ṡ R ǀ G R ǀ Ṡ R ǁ\r\nṠ R-Ṡ N ǀ D P ǀ M P ǁ\r\nD N Ṡ R ǀ Ṡ Ṡ ǀ R Ṡ ǁ\r\nṠ R-Ṡ N ǀ D P ǀ M P ǁ\r\nD N Ṡ R ǀ Ṡ N ǀ D P ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ',
      'S R G M ǀ P D ǀ N Ṡ ǁ\r\nṠ , , , ǀ Ṡ , ǀ , , ǁ\r\nD N Ṡ R ǀ G M ǀ P M ǁ\r\nG R-Ṡ N ǀ D P ǀ M P ǁ\r\nD N Ṡ R ǀ G M ǀ G R ǁ\r\nṠ R-Ṡ N ǀ D P ǀ M P ǁ\r\nD N Ṡ R ǀ G R ǀ Ṡ R ǁ\r\nṠ R-Ṡ N ǀ D P ǀ M P ǁ\r\nD N Ṡ R ǀ Ṡ Ṡ ǀ R Ṡ ǁ\r\nṠ R-Ṡ N ǀ D P ǀ M P ǁ\r\nD N Ṡ R ǀ Ṡ N ǀ D P ǁ\r\nṠ N D P ǀ M G ǀ R S ǁ'
    ]
  },
  {
    number: 5,
    varasai: 'Mandra Staayi',
    notes: [
      'Ṡ N D P ǀ M G ǀ R S ǁ\r\nS , , , ǀ S , ǀ , , ǁ\r\nG R S Ṇ ǀ S R ǀ G M ǁ\r\nS R G M ǀ P D ǀ N Ṡ ǁ',
      'Ṡ N D P ǀ M G ǀ R S ǁ\r\nS , , , ǀ S , ǀ , , ǁ\r\nG R S Ṇ ǀ S S ǀ Ṇ S ǁ\r\nS Ṇ S R ǀ G M ǀ P M ǁ\r\nG R S Ṇ ǀ S R ǀ G M ǁ\r\nS R G M ǀ P D ǀ N Ṡ ǁ',
      'Ṡ N D P ǀ M G ǀ R S ǁ\r\nS , , , ǀ S , ǀ , , ǁ\r\nG R S Ṇ ǀ D Ṇ ǀ S Ṇ ǁ\r\nS Ṇ S R ǀ G M ǀ P M ǁ\r\nG R S Ṇ ǀ S S ǀ Ṇ S ǁ\r\nS Ṇ S R ǀ G M ǀ P M ǁ\r\nG R S Ṇ ǀ S R ǀ G M ǁ\r\nS R G M ǀ P D ǀ N Ṡ ǁ',
      'Ṡ N D P ǀ M G ǀ R S ǁ\r\nS , , , ǀ S , ǀ , , ǁ\r\nG R S Ṇ ǀ D P̣ ǀ D Ṇ ǁ\r\nS Ṇ S R ǀ G M ǀ P M ǁ\r\nG R S Ṇ ǀ D Ṇ ǀ S Ṇ ǁ\r\nS Ṇ S R ǀ G M ǀ P M ǁ\r\nG R S Ṇ ǀ S S ǀ Ṇ S ǁ\r\nS Ṇ S R ǀ G M ǀ P M ǁ\r\nG R S Ṇ ǀ S R ǀ G M ǁ\r\nS R G M ǀ P D ǀ N Ṡ ǁ',
      'Ṡ N D P ǀ M G ǀ R S ǁ\r\nS , , , ǀ S , ǀ , , ǁ\r\nG R S Ṇ ǀ D P̣ ǀ m. P̣ ǁ\r\nD Ṇ S R ǀ G M ǀ P M ǁ\r\nG R S Ṇ ǀ D P̣ ǀ D Ṇ ǁ\r\nS Ṇ S R ǀ G M ǀ P M ǁ\r\nG R S Ṇ ǀ D Ṇ ǀ S Ṇ ǁ\r\nS Ṇ S R ǀ G M ǀ P M ǁ\r\nG R S Ṇ ǀ S S ǀ Ṇ S ǁ\r\nS Ṇ S R ǀ G M ǀ P M ǁ\r\nG R S Ṇ ǀ S R ǀ G M ǁ\r\nS R G M ǀ P D ǀ N Ṡ ǁ'
    ]
  }
]
