import React from 'react'

function About () {
  return (
    <div className='about'>
      <div class='container'>
        <div class='row align-items-center my-5'>
          <div class='col-lg-5'>
            <h1 class='font-weight-light'>test page</h1>
            <p>Ṡ N D P | M G | R S ||</p>
            <p> S , , , | S , | , , ||</p>
            <p>G R S Ṇ | D P̣ | D Ṇ || </p>
            <p> S Ṇ S R | G M | P M || </p>
            <p> G R S Ṇ | D Ṇ | S Ṇ || </p>
            <p> S Ṇ S R | G M | P M || </p>
            <p> G R S Ṇ | S S | Ṇ S || </p>
            <p>S Ṇ S R | G M | P M || </p>
            <p> G R S Ṇ | S R | G M || </p>
            <p> S R G M | P D | N Ṡ || </p>
            {/* Like many things, this website is a work in progress and we hope
              to keep improving on the content available and grow carnatick into
              a thoughtful community of passionate individuals. Please reach out
              if you have any suggestions or ideas for future development! :) */}
          </div>
          <div class='col-lg-4'>
            <img
              class='img-fluid rounded mb-4 mb-lg-0'
              src='http://placehold.it/400x400'
              alt=''
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
