import React from 'react'
import { Sunburst } from '@nivo/sunburst'
import data from './data.json'
import RagamCard from './RagamCard'
import { ragamData } from './ragamData'

// const handleClick = props => {
//   console.log(props)
// }

const SunburstExample = () => {
  return (
    <div>
      <Sunburst
        data={data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        height={0.8 * window.innerHeight}
        width={0.8 * window.innerHeight}
        id='name'
        value='loc'
        cornerRadius={2}
        borderWidth={1}
        borderColor='white'
        colors={{ scheme: 'set2' }}
        // childColor={{ from: 'color' }}
        childColor={{ from: 'color', modifiers: [['darker', 0.5]] }}
        // childColor='noinherit'
        animate={true}
        motionConfig='stiff'
        isInteractive={true}
        tooltip={function ({ id }) {
          return <RagamCard id={id} />
        }}
        onClick={clickedData => {
          if (isNaN(clickedData.id) === false) {
            const value = ragamData[parseInt(clickedData.id) - 1]
            var url = './ragam/'
            url = url.concat(value.number)
            console.log(url)
            window.location.href = url
          }
        }}
      />
    </div>
  )
}
export default SunburstExample
