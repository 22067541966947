export const lessonsData = [
  {
    text: 'Beginner Varasai',
    subtitle:
      'Basic exercises of Sarali, Jantai, Dattu, Melstaayi, and Mandra Staayi Varisai.',
    link: 'learn/varasai',
    image: 'http://placehold.it/370x250'
  },
  {
    text: 'Alankarams',
    subtitle: 'Exercises based on the seven talams to practice keeping rhythm.',
    link: 'learn/alankaram',
    image: 'http://placehold.it/370x250'
  },
  {
    text: 'Geethams',
    subtitle: 'Simple songs with same tempo throughout the song.',
    link: '#',
    image: 'http://placehold.it/370x250'
  },
  {
    text: 'Notes',
    subtitle:
      'Songs in simple ragams, often set to Western tunes with Indian lyrics.',
    link: '#',
    image: 'http://placehold.it/370x250'
  },
  {
    text: 'Swarajathis',
    subtitle:
      'The next level of musical composition with a longer, more complex structure.',
    link: '#',
    image: 'http://placehold.it/370x250'
  },
  {
    text: 'Varnams',
    subtitle:
      'Complex pieces with swara and lyrics that embody the features of a ragam.',
    link: '#',
    image: 'http://placehold.it/370x250'
  }
]
