import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Header, Home, About, Shruthi, Ragam, Lessons } from './components'
import { AnimatePresence } from 'framer-motion'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import RagamPage from './components/RagamPage'
import VarasaiPage from './components/VarasaiPage'
import AlankaramPage from './components/AlankaramPage'
import Alankaram from './components/Alankaram'
import Varasai from './components/Varasai'
import MelakarthaChart from './components/MelakarthaChart'

import ScrollToTop from './components/ScrollToTop'
import './App.css'

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Karla', sans-serif",
    subtitle1: {
      fontFamily: "'Cousine', sans-serif"
    },
    subtitle2: {
      fontFamily: "'Cousine', sans-serif"
    }
  },

  palette: {
    background: {
      default: '#f2e3cb'
    }
  }
})

function App () {
  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <Router>
          <ScrollToTop />
          <Header />
          <AnimatePresence>
            <Switch>
              <Route path='/' exact component={() => <Home />} />
              <Route path='/about' exact component={() => <About />} />
              <Route path='/shruthi' exact component={() => <Shruthi />} />
              <Route path='/ragam' exact component={() => <Ragam />} />
              <Route exact path='/ragam/:userId' component={RagamPage} />
              <Route
                exact
                path='/learn/varasai/:id/:lessonId'
                component={VarasaiPage}
              />
              <Route
                path='/learn/varasai'
                exact
                component={() => <Varasai />}
              />
              <Route
                exact
                path='/learn/alankaram/:lessonId'
                component={AlankaramPage}
              />
              <Route
                path='/learn/alankaram'
                exact
                component={() => <Alankaram />}
              />
              <Route path='/learn' exact component={() => <Lessons />} />
              <Route
                path='/melakartha-chart'
                exact
                component={() => <MelakarthaChart />}
              />
            </Switch>
          </AnimatePresence>
        </Router>
      </div>
    </ThemeProvider>
  )
}

export default App
