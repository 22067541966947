import React from 'react'

import { makeStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { ragamData } from './ragamData'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    // width: 400
    height: 50
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

export default function RagamSearch () {
  const classes = useStyles()

  return (
    <div>
      {/* <Paper component='form' className={classes.root}>
        <IconButton className={classes.iconButton} aria-label='menu'>
          <MenuIcon />
        </IconButton>
        <InputBase
          className={classes.input}
          placeholder='Search Melakartha Ragams'
          inputProps={{ 'aria-label': 'search ragams' }}
        />
        <IconButton
          color='primary'
          className={classes.iconButton}
          aria-label='search'
        >
          <SearchIcon />
        </IconButton>
      </Paper> */}

      <Autocomplete
        id='highlights-demo'
        // style={{ width: 300 }}
        options={ragamData}
        getOptionLabel={option => option.number + '. ' + option.name}
        onChange={(event, value, reason) => {
          // console.log(value)
          var url = './ragam/'
          url = url.concat(value.number)
          console.log(url)
          window.location.href = url
        }}
        renderInput={params => (
          <TextField
            {...params}
            label='Search Melakartha Ragams'
            variant='outlined'
            margin='normal'
          />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.number + '. ' + option.name, inputValue)
          const parts = parse(option.number + '. ' + option.name, matches)

          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          )
        }}
      />
    </div>
  )
}
