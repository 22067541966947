export const alankaramsLessonData = [
  {
    number: '1',
    ragam: 'Mayamalavagowla',
    talam: 'Chatusra Jati Dhruva Talam',
    talamNotation: 'IOII',
    notes:
      'S R G M ǀ G R ǀ S R G R ǀ S R G M ǁ\r\nR G M P ǀ M G ǀ R G M G ǀ R G M P ǁ\r\nG M P D ǀ P M ǀ G M P M ǀ G M P D ǁ\r\nM P D N ǀ D P ǀ M P D P ǀ M P D N ǁ\r\nP D N Ṡ ǀ N D ǀ P D N D ǀ P D N Ṡ ǁ\r\nṠ N D P ǀ D N ǀ Ṡ N D N ǀ Ṡ N D P ǁ\r\nN D P M ǀ P D ǀ N D P D ǀ N D P M ǁ\r\nD P M G ǀ M P ǀ D P M P ǀ D P M G ǁ\r\nP M G R ǀ G M ǀ P M G M ǀ P M G R ǁ\r\nM G R S ǀ R G ǀ M G R G ǀ M G R S ǁ'
  },
  {
    number: '2',
    ragam: 'Mayamalavagowla',
    talam: 'Chatusra Jati Matya Talam',
    talamNotation: 'IOI',
    notes:
      'S R G R ǀ S R ǀ S R G M ǁ\r\nR G M G ǀ R G ǀ R G M P ǁ\r\nG M P M ǀ G M ǀ G M P D ǁ\r\nM P D P ǀ M P ǀ M P D N ǁ\r\nP D N D ǀ P D ǀ P D N Ṡ ǁ\r\nṠ N D N ǀ Ṡ N ǀ Ṡ N D P ǁ\r\nN D P D ǀ N D ǀ N D P M ǁ\r\nD P M P ǀ D P ǀ D P M G ǁ\r\nP M G M ǀ P M ǀ P M G R ǁ\r\nM G R G ǀ M G ǀ M G R S ǁ'
  },
  {
    number: '3',
    ragam: 'Mayamalavagowla',
    talam: 'Chatusra Jati Rupaka Talam',
    talamNotation: 'OI',
    notes:
      'S R ǀ S R G M ǁ\r\nR G ǀ R G M P ǁ\r\nG M ǀ G M P D ǁ\r\nM P ǀ M P D N ǁ\r\nP D ǀ P D N Ṡ ǁ\r\nṠ N ǀ Ṡ N D P ǁ\r\nN D ǀ N D P M ǁ\r\nD P ǀ D P M G ǁ\r\nP M ǀ P M G R ǁ\r\nM G ǀ M G R S ǁ'
  },
  {
    number: '4',
    ragam: 'Mayamalavagowla',
    talam: 'Misra Jati Jhampa Talam',
    talamNotation: 'IUO',
    notes:
      'S R G S R S R ǀ G ǀ M , ǁ\r\nR G M R G R G ǀ M ǀ P , ǁ\r\nG M P G M G M ǀ P ǀ D , ǁ\r\nM P D M P M P ǀ D ǀ N , ǁ\r\nP D N P D P D ǀ N ǀ Ṡ , ǁ\r\nṠ N D Ṡ N Ṡ N ǀ D ǀ P , ǁ\r\nN D P N D N D ǀ P ǀ M , ǁ\r\nD P M D P D P ǀ M ǀ G , ǁ\r\nP M G P M P M ǀ G ǀ R , ǁ\r\nM G R M G M G ǀ R ǀ S , ǁ'
  },
  {
    number: '5',
    ragam: 'Mayamalavagowla',
    talam: 'Tisra Jati Triputa Talam',
    talamNotation: 'IOO',
    notes:
      'S R G ǀ S R ǀ G M ǁ\r\nR G M ǀ R G ǀ M P ǁ\r\nG M P ǀ G M ǀ P D ǁ\r\nM P D ǀ M P ǀ D N ǁ\r\nP D N ǀ P D ǀ N Ṡ ǁ\r\nṠ N D ǀ Ṡ N ǀ D P ǁ\r\nN D P ǀ N D ǀ P M ǁ\r\nD P M ǀ D P ǀ M G ǁ\r\nP M G ǀ P M ǀ G R ǁ\r\nM G R ǀ M G ǀ R S ǁ'
  },
  {
    number: '6',
    ragam: 'Mayamalavagowla',
    talam: 'Khanda Jati Ata Talam',
    talamNotation: 'IIOO',
    notes:
      'S R , G , ǀ S , R G , ǀ M , ǀ M , ǁ\r\nR G , M , ǀ R , G M , ǀ P , ǀ P , ǁ\r\nG M , P , ǀ G , M P , ǀ D , ǀ D , ǁ\r\nM P , D , ǀ M , P D , ǀ N , ǀ N , ǁ\r\nP D , N , ǀ P , D N , ǀ Ṡ , ǀ Ṡ , ǁ\r\nṠ N , D , ǀ Ṡ , N D , ǀ P , ǀ P , ǁ\r\nN D , P , ǀ N , D P , ǀ M , ǀ M , ǁ\r\nD P , M , ǀ D , P M , ǀ G , ǀ G , ǁ\r\nP M , G , ǀ P , M G , ǀ R , ǀ R , ǁ\r\nM G , R , ǀ M , G R , ǀ S , ǀ S , ǁ'
  },
  {
    number: '7',
    ragam: 'Mayamalavagowla',
    talam: 'Chatusra Jati Eka Talam',
    talamNotation: 'I',
    notes:
      'S R G M ǁ\r\nR G M P ǁ\r\nG M P D ǁ\r\nM P D N ǁ\r\nP D N Ṡ ǁ\r\nṠ N D P ǁ\r\nN D P M ǁ\r\nD P M G ǁ\r\nP M G R ǁ\r\nM G R S ǁ'
  },
  {
    number: '8',
    ragam: 'Mayamalavagowla',
    talam: 'Sankeerna Jati Eka Talam (v.1)',
    talamNotation: 'I',
    notes:
      'S , R , G , M P D ǁ\r\nR , G , M , P D N ǁ\r\nG , M , P , D N Ṡ ǁ\r\nṠ , N , D , P M G ǁ\r\nN , D , P , M G R ǁ\r\nD , P , M , G R S ǁ'
  },
  {
    number: '9',
    ragam: 'Mayamalavagowla',
    talam: 'Sankeerna Jati Eka Talam (v.2)',
    talamNotation: 'I',
    notes:
      'S R , G M , P D N ǁ\r\nR G , M P , D N Ṡ ǁ\r\nṠ N , D P , M G R ǁ\r\nN D , P M , G R S ǁ'
  }
]
