import React from 'react'
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  makeStyles,
  Box,
  Divider
} from '@material-ui/core'
// import { Link } from 'react-router-dom'
import { beginnerLessonData } from './beginnerLessonData'
import { motion } from 'framer-motion'
import LessonNavigation from './LessonNavigation'
import BreadCrumbNavigation from './BreadCrumbNavigation'

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,

    // display: 'flex',
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)

    // width: '100%'
    // height: 170
    // justifyContent: 'center'
    // align: 'center',
    // flexDirection: 'column'
  },
  details: {
    // display: 'flex'
    // align: 'center'
  },
  media: {
    // height: 250
  }
}))

function Varasai () {
  const classes = useStyles()
  const pageVariants2 = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  }
  function getLink (varasai) {
    var str1 = './varasai/'
    return str1.concat(varasai)
  }
  function getSteps (notes) {
    const len = notes.length
    const arr = []
    for (let i = 0; i < len; i++) {
      arr.push(' ')
    }

    return arr
  }

  return (
    <div className='home'>
      <div class='container'>
        <motion.div
          initial='initial'
          animate='in'
          exit='out'
          variants={pageVariants2}
          transition={{ duration: 0.6 }}
        >
          <div>
            <center>
              <BreadCrumbNavigation
                crumb1='Learn'
                crumb2='Beginner Varasai'
                crumb3=' '
                extraURLstring='.'
              />
            </center>
          </div>
          <div class='col align-items-center'>
            <center>
              <h2 class='font-weight-light'>beginner varasai</h2>
              <h6 class='font-weight-light'>
                Basic exercises of Sarali, Jantai, Dattu, Melstaayi, and Mandra
                Staayi Varisai.
              </h6>
            </center>
          </div>

          <div class='row align-items-center my-5'>
            <Grid container justify='center' alignItems='center' spacing='2'>
              <Card className={classes.root}>
                <CardContent>
                  {beginnerLessonData.map(value => (
                    <Grid key={value} item>
                      <Box>
                        <div className={classes.details}>
                          <Typography component='h6' variant='h6'>
                            {value.varasai} Varasai
                          </Typography>
                          <LessonNavigation
                            stepButtonLink={getLink(value.number)}
                            steps={getSteps(value.notes)}
                            titles={getSteps(value.notes)}
                            activeStep={100}
                          />
                        </div>
                      </Box>
                    </Grid>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Varasai
