import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3)
  }
}))

function handleClick (event) {
  //   event.preventDefault()
  //   console.info('You clicked a breadcrumb.')
}

export default function BreadCrumbNavigation (props) {
  const classes = useStyles()
  function getLink (string) {
    var str1 = props.extraURLstring
    return str1.concat(string)
  }

  return (
    <div className={classes.root}>
      {/* {props.crumb3 !== ' ' ? (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize='small' />}
          aria-label='breadcrumb'
        >
          <Link
            color='inherit'
            component={RouterLink}
            to={getLink('./')}
            onClick={handleClick}
          >
            {props.crumb1}
          </Link>
          <Link
            color='inherit'
            component={RouterLink}
            to={getLink('/')}
            onClick={handleClick}
          >
            {props.crumb2}
          </Link>
          <Typography color='textPrimary'>{props.crumb3}</Typography>
        </Breadcrumbs>
      ) : (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize='small' />}
          aria-label='breadcrumb'
        >
          <Link
            color='inherit'
            component={RouterLink}
            to='./'
            onClick={handleClick}
          >
            {props.crumb1}
          </Link>
          <Typography color='textPrimary'>{props.crumb2}</Typography>
        </Breadcrumbs>
      )} */}
    </div>
  )
}
