import React from 'react'
import { Piano, MidiNumbers } from 'react-piano'
import classNames from 'classnames'
import 'react-piano/dist/styles.css'

import DimensionsProvider from './DimensionsProvider'
import SoundfontProvider from './SoundfontProvider'

class PianoPlayer extends React.Component {
  state = {
    activeNotesIndex: 0,
    isPlaying: false,
    stopAllNotes: () => console.warn('stopAllNotes not yet loaded')
  }

  constructor (props) {
    super(props)
    this.playbackIntervalFn = null
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.isPlaying !== this.state.isPlaying) {
      if (this.state.isPlaying) {
        this.playbackIntervalFn = setInterval(() => {
          this.setState({
            activeNotesIndex:
              (this.state.activeNotesIndex + 1) % this.props.song.length
          })
        }, 200)
      } else {
        clearInterval(this.playbackIntervalFn)
        this.state.stopAllNotes()
        this.setState({
          activeNotesIndex: 0
        })
      }
    }
  }

  setPlaying = value => {
    this.setState({ isPlaying: value })
  }

  render () {
    const noteRange = {
      first: MidiNumbers.fromNote('c3'),
      last: MidiNumbers.fromNote('c4')
    }

    return (
      <div>
        <div className='mt-4'>
          <SoundfontProvider
            audioContext={this.props.audioContext}
            instrumentName='acoustic_grand_piano'
            // instrumentName='violin'
            hostname={this.props.soundfontHostname}
            onLoad={({ stopAllNotes }) => this.setState({ stopAllNotes })}
            render={({ isLoading, playNote, stopNote, stopAllNotes }) => (
              <DimensionsProvider>
                {({ containerWidth }) => (
                  <Piano
                    activeNotes={
                      this.state.isPlaying
                        ? this.props.song[this.state.activeNotesIndex]
                        : []
                    }
                    noteRange={noteRange}
                    width={300}
                    playNote={playNote}
                    stopNote={stopNote}
                    disabled={isLoading || !this.state.isPlaying}
                  />
                )}
              </DimensionsProvider>
            )}
          />
        </div>
        <div className='text-left'>
          <div>
            <br></br>
            <button
              className={classNames('btn', {
                'btn-outline-info': !this.state.isPlaying,
                'btn-outline-danger': this.state.isPlaying
              })}
              onClick={() => this.setPlaying(!this.state.isPlaying)}
            >
              {this.state.isPlaying ? 'Stop' : 'Play Ragam'}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default PianoPlayer
