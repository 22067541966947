import React from 'react'
import { motion } from 'framer-motion'
import SunburstExample from './SunburstExample'

function MelakarthaChart () {
  const pageVariants2 = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  }

  return (
    <div className='about'>
      <div class='container'>
        <motion.div
          initial='initial'
          animate='in'
          exit='out'
          variants={pageVariants2}
          transition={{ duration: 0.6 }}
        >
          {' '}
          <center>
            <h3 class='font-weight-light'>melakartha chart</h3>
            {/* <br></br> */}
          </center>
          <div>
            <center>
              <SunburstExample />
            </center>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default MelakarthaChart
